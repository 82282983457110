import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const create = async subscription => {
  const body = JSON.stringify({ subscription })

  return fetch(url(constants.api.subscription.create), {
    method: 'POST',
    headers: headers(),
    body,
  })
}

const update = async subscription => {
  const body = JSON.stringify({ subscription })

  return fetch(url(constants.api.subscription.update(subscription.uuid)), {
    method: 'PUT',
    headers: headers(),
    body,
  })
}

const getPdf = async uuid =>
  fetch(url(constants.api.subscription.pdf(uuid)), {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' },
  })

const get = async (cpf, cpfResponsible) =>
  fetch(url(constants.api.subscription.get(cpf, cpfResponsible)), {
    method: 'GET',
    headers: headers(),
  })

const validate = async subscription => {
  const body = JSON.stringify({ subscription })

  return fetch(url(constants.api.subscription.validate), {
    method: 'POST',
    headers: headers(),
    body,
  })
}

const subscriptionApi = {
  get,
  create,
  update,
  getPdf,
  validate,
}

export default subscriptionApi
