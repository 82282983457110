const hasValidAnswer = (question, answer) => {
  const questionOption = question.options.find(i => i.id === answer.answerId)

  if (!questionOption) {
    return false
  }

  switch (questionOption.kind) {
    case 'number':
      return !isNaN(answer.extra)
    case 'text':
      return !!answer.extra && `${answer.extra}`.length > 0
    default:
      return true
  }
}

const quizCompleted = (questions, answers) =>
  answers
    ? questions.filter(question => {
        const answer = answers.find(i => i.questionId === question.id)
        return !hasValidAnswer(question, answer || {})
      }).length === 0
    : false

const questions = { hasValidAnswer, quizCompleted }

export default questions
