import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  typo: {
    margin: '12px 0 4px',
  },
}))

const BoldLabel = ({ children, ...rest }) => (
  <Typography className={useStyles().typo} variant="subtitle2" {...rest}>
    {children}
  </Typography>
)

export default BoldLabel
