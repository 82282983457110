import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { Fragment } from 'react'

import { Alert, Subtitle } from '../../../../components'
import { Main } from '../../../../components/Layout'

const useStyles = makeStyles(() => ({
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
  list: {
    padding: '0',
    marginTop: '8px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
  divider: {
    margin: '16px 0',
  },
}))

const Selection = ({ data, setEnrollment }) => {
  const classes = useStyles()

  const renderList = () => (
    <List component="ul" className={classes.list}>
      {data.map((enrollment, idx) => (
        <Fragment key={enrollment.id}>
          <ListItem component="li" button disabled={false} selected={false}>
            <ListItemText
              primary={enrollment.school}
              secondary={`${enrollment.degree} - Turma: ${enrollment.classroom}`}
              onClick={() => setEnrollment(enrollment)}
            />
          </ListItem>

          {idx < data.length - 1 && <Divider component="li" />}
        </Fragment>
      ))}
    </List>
  )

  const renderEmpty = () => (
    <Alert>
      <strong>Atenção!</strong>
      <br />
      Neste momento não há nenhuma matrícula ativa dentro do período de
      renovação. Tente novamente mais tarde ou consulte a Secretaria de Educação
      para ajuda.
    </Alert>
  )

  return (
    <Main className={classes.main}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle>
            Escolha a matrícula do aluno para solicitar a renovação
          </Subtitle>
        </Grid>

        <Grid item xs={12}>
          {data.length > 0 ? renderList() : renderEmpty()}
        </Grid>
      </Grid>
    </Main>
  )
}

export default Selection
