import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { Subtitle } from '../../../../../../components'

import AnswersBuilder from './AnswersBuilder'

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '20px',
  },
}))

const Quiz = ({ divider = true, questions = [], quiz }) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        <Subtitle>Respostas do questionário</Subtitle>
      </Grid>

      <AnswersBuilder questions={questions} answers={quiz.answers} />

      {divider && (
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </>
  )
}

export default Quiz
