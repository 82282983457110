import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { FormControl } from '../../../../../../components/Forms'
import { Subtitle } from '../../../../../../components'
import { Builder } from '../../../../../../components/Questionnaire'

import { intentService } from '../../../../../../services'
import { errors as errorUtils, strings } from '../../../../../../utils'

const { errorFromMessages } = errorUtils
const { limit, upcase } = strings

const handleText = text => upcase(limit(text, 250))

const useStyles = makeStyles(() => ({
  autocomplete: {
    '& > label': {
      color: '#7B7B7A !important',
    },
  },
  info: {
    marginTop: '16px',
  },
  divider: {
    margin: '28px 0 28px',
    width: '100%',
  },
}))

const Option = ({
  degrees,
  divider = false,
  errors,
  exceptOfferIds = '',
  label,
  loading,
  option,
  prevOptIsCompleted = false,
  questions,
  validating,
  setLoading,
  update,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matchBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  const [autocomplete, setAutocomplete] = useState('')
  const [cities, setCities] = useState([])
  const [schools, setSchools] = useState([])
  const [shifts, setShifts] = useState([])
  const [curriculums, setCurriculums] = useState([])

  const { degreeRef, degreeName, cityRef, schoolRef, shift, curriculumRef } =
    option

  const shouldDisableAnything = () => loading || !prevOptIsCompleted

  const shouldDisableDegree = () =>
    shouldDisableAnything() || !degrees.length || validating

  const shouldDisableCity = () => shouldDisableDegree() || !cities.length

  const shouldDisableSchool = () =>
    shouldDisableCity() || !cityRef || !schools.length

  const shouldDisableShift = () =>
    shouldDisableSchool() || !schoolRef || !shifts.length

  const shouldDisableCurriculum = () =>
    shouldDisableShift() || !shift || !curriculums.length

  const setAnswer = questionId => (answerId, extra) => {
    update({ schoolQuestions: { questionId, answerId, extra } })
  }

  const shouldDisableQuestions = () => shouldDisableAnything() || !curriculumRef
  const lowOpacityStyle = () => (prevOptIsCompleted ? {} : { opacity: '.5' })
  const findDegreeName = ref => (degrees.find(i => i.value === ref) || {}).label
  const findCityRefByName = name =>
    (cities.find(i => i.label === name) || {}).value
  const findSchoolName = ref => (schools.find(i => i.value === ref) || {}).label
  const error = () => errorFromMessages(errors, 'intents.degree', degreeName)
  const hasError = () => !!error()

  const findCurriculumName = ref =>
    (curriculums.find(i => i.value === ref) || {}).label

  const onChangeCurriculum = curriculumRef => {
    update({
      curriculumRef,
      curriculumName: findCurriculumName(curriculumRef),
      offerId: (curriculums.find(i => i.value === curriculumRef) || {}).id,
    })
  }

  const manualInputProps = () => (cityRef ? { shrink: true } : {})

  const tryToGetAvailableCities = async () => {
    setLoading(true)

    const {
      success,
      payload: { cities: _cities },
    } = await intentService.getAvailableCities(degreeRef)

    if (success) {
      setCities(_cities)
    }

    setLoading(false)
  }

  const tryToGetAvailableSchools = async () => {
    setLoading(true)

    const {
      success,
      payload: { schools: _schools },
    } = await intentService.getAvailableSchools(
      degreeRef,
      cityRef,
      exceptOfferIds
    )

    if (success) {
      setSchools(_schools)
    }

    setLoading(false)
  }

  const tryToGetAvailableShifts = async () => {
    setLoading(true)

    const {
      success,
      payload: { shifts: _shifts },
    } = await intentService.getAvailableShifts(
      degreeRef,
      schoolRef,
      exceptOfferIds
    )

    if (success) {
      setShifts(_shifts)
    }

    setLoading(false)
  }

  const tryToGetAvailableCurriculums = async () => {
    setLoading(true)

    const {
      success,
      payload: { curriculums: _curriculums },
    } = await intentService.getAvailableCurriculums(
      degreeRef,
      schoolRef,
      shift,
      exceptOfferIds
    )

    if (success) {
      setCurriculums(_curriculums)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (degreeRef) {
      tryToGetAvailableCities()
    }
  }, [degreeRef])

  useEffect(() => {
    if (degreeRef && cityRef) {
      tryToGetAvailableSchools()
    }
  }, [cityRef])

  useEffect(() => {
    if (degreeRef && schoolRef) {
      tryToGetAvailableShifts()
    }
  }, [schoolRef])

  useEffect(() => {
    if (degreeRef && schoolRef && shift) {
      tryToGetAvailableCurriculums()
    }
  }, [shift])

  const renderCityAutocomplete = () => {
    const citiesStr = cities.map(i => i.label)
    const value = option.cityName || ''

    return (
      <Grid item xs={12}>
        <FormControl variant="outlined">
          <Autocomplete
            style={{ color: 'red' }}
            openText="Abrir"
            closeText="Fechar"
            clearText="Limpar"
            noOptionsText="Nada encontrado"
            disableClearable
            disabled={shouldDisableCity()}
            id="cityRef"
            options={citiesStr}
            value={value}
            inputValue={autocomplete}
            onChange={(_, cityName) => {
              update({
                cityRef: findCityRefByName(cityName),
                cityName,
              })
            }}
            onInputChange={(_, val) => {
              const cityName = handleText(val)
              const cityRef = findCityRefByName(cityName)

              setAutocomplete(cityName)

              if (cityRef) {
                update({
                  cityRef: findCityRefByName(cityName),
                  cityName,
                })
              }
            }}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  className={classes.autocomplete}
                  InputLabelProps={manualInputProps()}
                  label="Cidade"
                  variant="outlined"
                />
              )
            }}
          />
        </FormControl>
      </Grid>
    )
  }

  return (
    <Grid item md={4} xs={12} style={lowOpacityStyle()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle>{label}</Subtitle>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" error={hasError()}>
            <InputLabel htmlFor="degreeRef">
              Ano de escolaridade que pretende cursar
            </InputLabel>
            <Select
              disabled={shouldDisableDegree()}
              error={hasError()}
              label="Ano de escolaridade que pretende cursar"
              id="degreeRef"
              value={option.degreeRef || ''}
              onChange={e => {
                setAutocomplete('')

                update({
                  degreeRef: e.target.value,
                  degreeName: findDegreeName(e.target.value),
                })
              }}
            >
              {degrees.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {renderCityAutocomplete()}

        <Grid item xs={12}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="schoolRef">Escola de preferência</InputLabel>
            <Select
              disabled={shouldDisableSchool()}
              label="Escola de preferência"
              id="schoolRef"
              value={option.schoolRef || ''}
              onChange={e =>
                update({
                  schoolRef: e.target.value,
                  schoolName: findSchoolName(e.target.value),
                })
              }
            >
              {schools.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="shift">Turno de preferência</InputLabel>
            <Select
              disabled={shouldDisableShift()}
              label="Turno de preferência"
              id="shift"
              value={option.shift || ''}
              onChange={e => update({ shift: e.target.value })}
            >
              {shifts.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="curriculumRef">Oferta</InputLabel>
            <Select
              disabled={shouldDisableCurriculum()}
              label="Oferta"
              id="curriculumRef"
              value={option.curriculumRef || ''}
              onChange={e => onChangeCurriculum(e.target.value)}
            >
              {curriculums.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {questions.length > 0 && (
          <>
            <Grid item xs={12} className={classes.info}>
              <Subtitle>Informações extras</Subtitle>
            </Grid>

            <Builder
              disableQuestions={shouldDisableQuestions()}
              questions={questions}
              validating={validating}
              setAnswer={setAnswer}
            />
          </>
        )}

        {matchBreakpoint && divider && <Divider className={classes.divider} />}
      </Grid>
    </Grid>
  )
}

export default Option
