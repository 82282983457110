import 'fontsource-roboto'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import React from 'react'

import App from './App'
import * as serviceWorker from './serviceWorker'

import './assets/print.css'
import { constants } from './utils'

const { dsn } = constants.params.sentry

Sentry.init({
  dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
