import questions from './questions'

const { hasValidAnswer } = questions

const questionsFor = questions => option => {
  const [key, opt] = Object.entries(option)[0]

  const answers = opt.answers || []

  return questions
    .filter(i => i[key])
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
    .map(question => {
      if (answers.length > 0) {
        const { answerId, extra } =
          answers.find(i => i.questionId === question.id) || {}

        return {
          ...question,
          answerId,
          extra,
        }
      }

      return question
    })
}

const optComplete = questions => option => {
  const [key, opt] = Object.entries(option)[0]

  if (!key || !opt) {
    return false
  }

  const answered = !questionsFor(questions)({ [key]: opt }).filter(
    question =>
      !(opt.answers || []).some(
        answer =>
          answer.questionId === question.id && hasValidAnswer(question, answer)
      )
  ).length

  return (
    !!opt.cityRef &&
    !!opt.schoolRef &&
    !!opt.shift &&
    !!opt.curriculumRef &&
    !!opt.offerId &&
    answered
  )
}

const updateOpt = (update, questions) => option => attr => {
  const [key, opt] = Object.entries(option)[0]

  let attributes = {}
  let check = {}

  if ('schoolQuestions' in attr) {
    const { questionId, answerId, extra } = attr['schoolQuestions'] || {}

    let answers = opt.answers || []

    if (answers.some(i => i.questionId === questionId)) {
      answers = answers.map(answer => {
        if (answer.questionId === questionId) {
          return {
            ...answer,
            answerId,
            extra,
          }
        }

        return answer
      })
    } else {
      answers.push({ questionId, answerId, extra })
    }

    check = { ...opt, answers }

    attributes = {
      [key]: { ...check, completed: optComplete(questions)({ [key]: check }) },
    }
  } else {
    let payload = attr

    const changes = Object.keys(attr)

    switch (true) {
      case changes.includes('degreeRef'):
        payload = {
          ...payload,
          cityRef: undefined,
          schoolRef: undefined,
          shift: undefined,
          curriculumRef: undefined,
          offerId: undefined,
          answers: [],
        }

        break
      case changes.includes('cityRef'):
        payload = {
          ...payload,
          schoolRef: undefined,
          shift: undefined,
          curriculumRef: undefined,
          offerId: undefined,
          answers: [],
        }

        break
      case changes.includes('schoolRef'):
        payload = {
          ...payload,
          shift: undefined,
          curriculumRef: undefined,
          offerId: undefined,
          answers: [],
        }

        break
      case changes.includes('shift'):
        payload = {
          ...payload,
          curriculumRef: undefined,
          offerId: undefined,
          answers: [],
        }
        break
      case changes.includes('curriculumRef'):
        payload = { ...payload, answers: [] }
        break
      default:
        payload = { ...payload }
    }

    check = { ...opt, ...payload }

    attributes = {
      [key]: { ...check, completed: optComplete(questions)({ [key]: check }) },
    }

    switch (key) {
      case 'optOne':
        attributes = { ...attributes, optTwo: {}, optThree: {} }
        break
      case 'optTwo':
        attributes = { ...attributes, optThree: {} }
        break
      default:
        attributes = { ...attributes }
    }
  }

  update(attributes)
}

const options = { updateOpt, questionsFor, optComplete }
export default options
