import { addressApi } from '../api'

const getAllStates = async () => {
  try {
    const response = await addressApi.getAllStates()

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const states = responseBody.data.map(({ id, attributes: a }) => ({
        value: id,
        label: a.acronym,
      }))

      return {
        success: true,
        payload: { states },
      }
    }
  } catch {}

  return { success: false, payload: { states: [] }, error: 'UnhandledError' }
}

const getCitiesByState = async stateId => {
  try {
    const response = await addressApi.getCitiesByState(stateId)

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const cities = responseBody.data.map(({ id, attributes: a }) => ({
        ...a,
        value: id,
        label: a.name,
      }))

      return {
        success: true,
        payload: { cities },
      }
    }
  } catch {}

  return { success: false, payload: { cities: [] }, error: 'UnhandledError' }
}

const searchAddressByCityAndZipCodeOrStreet = async (cityId, term) => {
  try {
    const response = await addressApi.searchByCity(cityId, term)

    const { ok, status } = response

    if (ok && [200, 304].includes(status)) {
      const responseBody = await response.json()

      const addresses = responseBody.data.map(({ id, attributes: a }) => ({
        ...a,
        id,
        name: a.label,
      }))

      return {
        success: true,
        payload: { addresses },
      }
    }
  } catch {}

  return { success: false, payload: { addresses: [] }, error: 'UnhandledError' }
}

const addressService = {
  getAllStates,
  getCitiesByState,
  searchAddressByCityAndZipCodeOrStreet,
}

export default addressService
