import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

import {
  errors as errorUtils,
  questions as questionUtils,
} from '../../../../utils'
import { quizService } from '../../../../services'
import { Editing, Error, LoadingButton, StepHint } from '../../../../components'
import { Main } from '../../../../components/Layout'
import { Buttons } from '../../../../components/Steps'
import { Subtitle } from '../../../../components'
import { Builder } from '../../../../components/Questionnaire'

const useStyles = makeStyles(() => ({
  notFound: {
    margin: '0 6px',
    width: '100%',
    color: '#9c5c6a',
  },
  error: {
    marginBottom: '16px',
  },
  ul: {
    margin: '8px 0 0',
    paddingLeft: '24px',
  },
}))

const Quiz = ({ data, errors, prev, persist, validating }) => {
  const classes = useStyles()

  const errorMessage = errorUtils.errorMessage(errors)

  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState([])
  const [quiz, setQuiz] = useState({ ...data })

  const shouldDisableNextButton = () =>
    !questionUtils.quizCompleted(questions, quiz.answers)

  const setAnswer = questionId => (answerId, extra) => {
    let answers = quiz.answers || []

    if (answers.some(i => i.questionId === questionId)) {
      answers = answers.map(answer => {
        if (answer.questionId === questionId) {
          return {
            ...answer,
            answerId,
            extra,
          }
        }

        return answer
      })
    } else {
      answers.push({ questionId, answerId, extra })
    }

    setQuiz({ ...quiz, answers })
  }

  const handledQuestions = () => {
    const answers = quiz.answers || []

    return questions
      .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      .map(question => {
        if (answers.length > 0) {
          const { answerId, extra } =
            answers.find(i => i.questionId === question.id) || {}

          return {
            ...question,
            answerId,
            extra,
          }
        }

        return question
      })
  }

  const tryToGetQuestions = async () => {
    setLoading(true)

    const {
      success,
      payload: { questions: _questions },
    } = await quizService.getQuestions()

    if (success) {
      setQuestions(_questions)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetQuestions()
  }, [])

  const renderErrors = () => {
    const _errors = [
      errorMessage('answers'),
      errorMessage('answers.extra'),
    ].filter(i => !!i)

    if (_errors.length > 0) {
      return (
        <Grid item xs={12} className={classes.error}>
          <Error>
            <strong>Uma ou mais questões contém erros:</strong>

            <ul className={classes.ul}>
              {_errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          </Error>
        </Grid>
      )
    }

    return <></>
  }

  return (
    <>
      <StepHint>
        Pergunta(s) com relação ao processo de inscrição e/ou a situação atual
        do candidato
      </StepHint>

      <Main>
        <Editing />

        {renderErrors()}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle>Responda às seguintes perguntas</Subtitle>
          </Grid>

          {loading ? (
            <CircularProgress size={20} />
          ) : questions.length > 0 ? (
            <Builder
              questions={handledQuestions()}
              validating={validating}
              setAnswer={setAnswer}
            />
          ) : (
            <p className={classes.notFound}>
              ERRO: Não foi possível carregar as perguntas, consulte a
              Secretaria de Educação para ajuda
            </p>
          )}
        </Grid>
      </Main>

      <Buttons>
        <Button variant="contained" color="primary" onClick={prev}>
          Voltar
        </Button>

        <LoadingButton
          disabled={shouldDisableNextButton() || validating}
          loading={validating}
          onClick={persist({ quiz })}
        >
          Próxima
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Quiz
