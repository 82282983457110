const trueAndFalse = [
  { value: true, label: 'SIM' },
  { value: false, label: 'NÃO' },
]

const genders = [
  { value: 1, label: 'FEMININO' },
  { value: 2, label: 'MASCULINO' },
  { value: 0, label: 'NÃO INFORMADO' },
]

const studyPlaces = [
  { value: 'municipal', label: 'REDE MUNICIPAL (PREFEITURA)' },
  { value: 'state', label: 'REDE ESTADUAL' },
  { value: 'private', label: 'REDE PRIVADA (PARTICULAR)' },
  { value: 'federal', label: 'REDE FEDERAL' },
  { value: 'none', label: 'NÃO ESTUDA' },
]

const responsibleKinds = [
  { value: 'parent_one', label: 'FILIAÇÃO 1' },
  { value: 'parent_two', label: 'FILIAÇÃO 2' },
  { value: 'self', label: 'O PRÓPRIO' },
  { value: 'sibling', label: 'IRMÃ/IRMÃO' },
  { value: 'step_parent', label: 'MADRASTA/PADRASTO' },
  { value: 'tutor', label: 'TUTOR' },
  { value: 'grandparent', label: 'AVÓ/AVÔ' },
  { value: 'other', label: 'OUTROS' },
]

const shifts = [
  { value: 1, label: 'MANHÃ' },
  { value: 2, label: 'TARDE' },
  { value: 3, label: 'NOITE' },
  { value: 4, label: 'INTEGRAL' },
]

const disabilities = [
  { value: 4, label: 'DEFICIENCIA MULTIPLA' },
  { value: 6, label: 'DEFICIENCIA FISICA' },
  { value: 7, label: 'SURDOCEGUEIRA' },
  { value: 8, label: 'DEFICIENCIA AUDITIVA' },
  { value: 9, label: 'SURDEZ' },
  { value: 10, label: 'BAIXA VISAO' },
  { value: 11, label: 'CEGUEIRA' },
  { value: 16, label: 'DEFICIENCIA INTELECTUAL' },
  { value: 59, label: 'VISAO MONOCULAR' },
]

const disorders = [
  { value: 1, label: 'SINDROME DE RETT' },
  { value: 2, label: 'SINDROME DE ASPERGER' },
  { value: 3, label: 'TRANSTORNO DO ESPECTRO AUTISTA' },
  {
    value: 13,
    label: 'TRANSTORNO DESINTEGRATIVO DA INFANCIA (PSICOSE INFANTIL)',
  },
]

const giftedness = [{ value: 15, label: 'ALTAS HABILIDADES SUPERDOTACAO' }]

const availableEnums = {
  trueAndFalse,
  genders,
  studyPlaces,
  responsibleKinds,
  shifts,
  disabilities,
  disorders,
  giftedness,
}

const findByValue = (enumerator, value) =>
  (availableEnums[enumerator].find(i => i.value === value) || {}).label

const enums = {
  findByValue,
  genders,
  responsibleKinds,
  studyPlaces,
  trueAndFalse,
  disabilities,
  disorders,
  giftedness,
}

export default enums
