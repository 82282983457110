import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

import { appService, confirmationService } from '../../../../../services'
import { Editing, LoadingButton, StepHint } from '../../../../../components'
import { constants } from '../../../../../utils'

import { Main } from '../../../../../components/Layout'
import { Buttons } from '../../../../../components/Steps'

import { Address, Agreed, Intent, Quiz, Responsible, Student } from './elements'

const Confirmation = ({ data, prev, tryToSubmit, submitting }) => {
  const history = useHistory()

  const [agreed, setAgreed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])

  const intentQuestions = () => questions.filter(i => i.kind === 'school')
  const quizQuestions = () => questions.filter(i => i.kind === 'subscription')

  const buttonTitle = () => (data.uuid ? 'atualização' : 'inscrição')

  const tryToGetQuestions = async () => {
    setLoading(true)

    const {
      success,
      payload: { questions: _questions },
    } = await confirmationService.getQuestions()

    if (success) {
      setQuestions(_questions)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetQuestions()
  }, [])

  useEffect(() => {
    if (appService.shouldRedirectToHome()) {
      history.push(constants.routes.home.path)
    }
  }, [])

  return (
    <>
      <StepHint>
        Verifique se todos os dados foram preenchidos corretamente, caso alguma
        informação esteja incorreta, realize as alterações necessárias voltando
        para a etapa anterior
      </StepHint>

      <Main>
        <Editing />

        <Grid container spacing={1}>
          <Student student={data.student} />
          <Address address={data.address} />
          <Responsible responsible={data.responsible} student={data.student} />
          <Intent intent={data.intent} questions={intentQuestions()} />
          <Quiz questions={quizQuestions()} quiz={data.quiz} />
          <Agreed
            agreed={agreed}
            submitting={submitting}
            setAgreed={setAgreed}
          />
        </Grid>
      </Main>

      <Buttons>
        <Button
          color="primary"
          disabled={loading || submitting}
          onClick={prev}
          variant="contained"
        >
          Voltar
        </Button>

        <LoadingButton
          disabled={loading || submitting || !agreed}
          loading={loading || submitting}
          onClick={tryToSubmit}
        >
          {`Confirmar ${buttonTitle()}`}
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Confirmation
