import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { DescriptionItem, Subtitle } from '../../../../components'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '16px 0',
  },
}))

const Info = ({ data }) => (
  <>
    <Grid item xs={12}>
      <Subtitle>Inscrição</Subtitle>
    </Grid>

    <DescriptionItem label="Identificador" value={data.uuid} md={12} />

    <DescriptionItem label="Realizada em" value={data.createdAt} md={3} />
    <DescriptionItem label="IP Público" value={data.ip} md={3} />
    <DescriptionItem label="Status" value={data.status} md={3} />

    <DescriptionItem
      label="Código de verificação"
      value={data.verificationCode}
      md={3}
    />

    {data.updated && (
      <DescriptionItem
        label="Ultima atualização em"
        value={data.updatedAt}
        md={12}
      />
    )}

    <Grid item xs={12}>
      <Divider className={useStyles().divider} />
    </Grid>
  </>
)

export default Info
