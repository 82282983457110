import { subscriptionApi } from '../api'
import { params, subscription } from '../utils'

const { prepareToApi } = params

const sendToValidate = async (data, step) => {
  try {
    const response = await subscriptionApi.validate(prepareToApi(data, step))

    const { status } = response

    if (status === 200) {
      return {
        success: true,
        payload: { errors: {} },
      }
    }

    if (status === 422) {
      const { errors } = await response.json()

      return {
        success: false,
        payload: { errors },
      }
    }
  } catch {}

  return { success: false, payload: { errors: [] }, error: 'UnhandledError' }
}

const tryToSubmit = async subscription => {
  try {
    let response

    if (subscription.uuid) {
      response = await subscriptionApi.update(prepareToApi(subscription))
    } else {
      response = await subscriptionApi.create(prepareToApi(subscription))
    }

    const { status: resStatus } = response
    const { data, errors } = await response.json()

    if ([200, 201].includes(resStatus)) {
      const {
        id,
        attributes: {
          createdAt,
          uuid,
          verificationCode,
          status,
          ip,
          updated,
          updatedAt,
        },
      } = data

      return {
        success: true,
        payload: {
          confirmation: {
            id,
            createdAt,
            uuid,
            verificationCode,
            status,
            ip,
            updated,
            updatedAt,
          },
          errors: {},
        },
      }
    }

    if (resStatus === 422) {
      return {
        success: false,
        payload: { errors },
      }
    }
  } catch {}

  return { success: false, payload: { errors: [] }, error: 'UnhandledError' }
}

const getPdf = async uuid => {
  try {
    const response = await subscriptionApi.getPdf(uuid)

    const { ok, status } = response

    if (ok && status === 200) {
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      let tag = document.createElement('a')

      tag.href = url
      tag.download = `comprovante-inscricao-${uuid}.pdf`

      document.body.appendChild(tag)

      tag.click()
      tag.remove()
    }
  } catch (e) {
    console.error(e)
  }
}

const get = async (cpf, cpfResponsible) => {
  try {
    const response = await subscriptionApi.get(cpf, cpfResponsible)

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      return {
        success: true,
        payload: subscription.fromApiToState(responseBody),
      }
    }
  } catch {}

  return {
    success: false,
    payload: {},
  }
}

const subscriptionService = {
  get,
  getPdf,
  sendToValidate,
  tryToSubmit,
}

export default subscriptionService
