import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const getAllStates = async () =>
  fetch(url(constants.api.address.states), {
    method: 'GET',
    headers: headers(),
  })

const getCitiesByState = async stateId =>
  fetch(url(constants.api.address.cities(stateId)), {
    method: 'GET',
    headers: headers(),
  })

const searchByCity = async (id, term) =>
  fetch(url(constants.api.address.searchByCityAndTerm(id, term)), {
    method: 'GET',
    headers: headers(),
  })

const addressApi = {
  getAllStates,
  getCitiesByState,
  searchByCity,
}

export default addressApi
