import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const getInfo = async () =>
  fetch(url(constants.api.publicCall.info), {
    method: 'GET',
    headers: headers(),
  })

const getAllPublicCallDegrees = async () =>
  fetch(url(constants.api.publicCall.degrees), {
    method: 'GET',
    headers: headers(),
  })

const getCitiesByDegree = async ref =>
  fetch(url(constants.api.publicCall.cities(ref)), {
    method: 'GET',
    headers: headers(),
  })

const getSchoolsByDegreeAndCity = async (degree, city, exceptOfferIds) =>
  fetch(url(constants.api.publicCall.schools(degree, city, exceptOfferIds)), {
    method: 'GET',
    headers: headers(),
  })

const getShiftsByDegreeAndSchool = async (degree, school, exceptOfferIds) =>
  fetch(url(constants.api.publicCall.shifts(degree, school, exceptOfferIds)), {
    method: 'GET',
    headers: headers(),
  })

const getCurriculumsByDegreeAndSchoolAndShift = async (
  degree,
  school,
  shift,
  exceptOfferIds
) =>
  fetch(
    url(
      constants.api.publicCall.curriculums(
        degree,
        school,
        shift,
        exceptOfferIds
      )
    ),
    {
      method: 'GET',
      headers: headers(),
    }
  )

const getSchoolsQuestions = async () =>
  fetch(url(constants.api.publicCall.schoolsQuestions), {
    method: 'GET',
    headers: headers(),
  })

const getQuizQuestions = async () =>
  fetch(url(constants.api.publicCall.quizQuestions), {
    method: 'GET',
    headers: headers(),
  })

const getQuestions = async () =>
  fetch(url(constants.api.publicCall.questions), {
    method: 'GET',
    headers: headers(),
  })

const genericApi = {
  getInfo,
  getAllPublicCallDegrees,
  getCitiesByDegree,
  getSchoolsByDegreeAndCity,
  getShiftsByDegreeAndSchool,
  getCurriculumsByDegreeAndSchoolAndShift,
  getSchoolsQuestions,
  getQuizQuestions,
  getQuestions,
}

export default genericApi
