import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { Alert, Subtitle } from '../../../../../../components'

import OptionBuilder from './OptionBuilder'

const useStyles = makeStyles(() => ({
  description: {
    margin: '0 0 16px',
  },
  divider: {
    margin: '16px 0',
  },
  alert: {
    margin: '8px 0 16px',
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: '0',
  },
}))

const Intent = ({ intent, questions = [] }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle className={classes.subtitle}>
            Escolas, turnos e ofertas
          </Subtitle>
        </Grid>

        <Grid item xs={12} className={classes.alert}>
          <Alert>
            A escolha das opções abaixo não é garantia de vaga nas escolas e
            turnos selecionados. Os critérios de seleção seguem as regras
            estabelecidas pela portaria.
          </Alert>
        </Grid>

        <OptionBuilder
          divider
          label="Primeira opção"
          option={{ key: 'optOne', data: intent.optOne }}
          questions={questions}
        />

        <OptionBuilder
          divider
          label="Segunda opção (opcional)"
          option={{ key: 'optTwo', data: intent.optTwo }}
          questions={questions}
        />

        <OptionBuilder
          label="Terceira opção (opcional)"
          option={{ key: 'optThree', data: intent.optThree }}
          questions={questions}
        />

        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Intent
