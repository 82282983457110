import moment from 'moment'
import 'moment/locale/pt-br'

const format = (date, format = 'L', origin = null) =>
  moment(date, origin).format(format)

const now = (format = 'L') => moment().format(format)

const parsePtBr = date => moment(date, 'DD/MM/YYYY')

const ofLegalAge = birthdate => {
  return parsePtBr(birthdate).isSameOrBefore(moment().subtract(18, 'years'))
}

const dates = { format, now, parsePtBr, ofLegalAge }
export default dates
