import Grid from '@material-ui/core/Grid'
import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '20px',
  },
}))

const Agreed = ({ agreed, validating, setAgreed }) => (
  <>
    <Grid container spacing={1} className={useStyles().marginTop}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreed}
              disabled={validating}
              onChange={e => setAgreed(e.target.checked)}
              name="agreed"
              color="primary"
            />
          }
          label="Estou de acordo que a escolha das opções acima não é garantia de vaga nas escolas e turnos selecionados. Os critérios de seleção seguem as regras estabelecidas pela portaria."
        />
      </Grid>
    </Grid>
  </>
)

export default Agreed
