import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'

import { Editing, LoadingButton, StepHint } from '../../../../../components'
import { Main } from '../../../../../components/Layout'
import { Buttons } from '../../../../../components/Steps'

import Form from './elements/Form'

const reset = {
  kind: '',
  name: '',
  cpf: '',
}

const Responsible = ({ data, errors, prev, persist, validating }) => {
  const [responsible, setResponsible] = useState({ ...data.responsible })

  const update = attr => {
    setResponsible({ ...responsible, ...attr })
  }

  useEffect(() => {
    if (
      (responsible.kind === 'parent_one' && data.student.unknownParentOne) ||
      (responsible.kind === 'parent_two' && data.student.unknownParentTwo)
    ) {
      update(reset)
    }
  }, [responsible])

  return (
    <>
      <StepHint>
        Nesta etapa, todos os campos devem ser preenchidos com dados da pessoa
        que será responsável pelo aluno candidato
      </StepHint>

      <Main>
        <Editing />

        <Form
          errors={errors}
          responsible={responsible}
          validating={validating}
          student={data.student}
          update={update}
        />
      </Main>

      <Buttons>
        <Button
          color="primary"
          disabled={validating}
          onClick={prev}
          variant="contained"
        >
          Voltar
        </Button>

        <LoadingButton
          disabled={validating}
          loading={validating}
          onClick={persist({ responsible })}
        >
          Próxima
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Responsible
