import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px auto',
    width: '100%',
    padding: '30px',
  },
}))

const Main = ({ children, ...rest }) => (
  <Paper className={useStyles().main} {...rest}>
    {children}
  </Paper>
)

export default Main
