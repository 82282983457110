import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'

import { Main } from '../../../../components/Layout'
import { DescriptionItem, Subtitle } from '../../../../components'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '24px 0 16px',
  },
  subtitle: {
    fontWeight: 600,
    marginBottom: '0px',
  },
}))

const Classification = ({ data }) => {
  const classes = useStyles()

  const {
    cityName,
    curriculumName,
    pos,
    publishInfo,
    schoolName,
    shift,
    status,
  } = data.classification

  const renderStatus = () => {
    if (pos) {
      return `${status} (${pos}ª POSIÇÃO)`
    }

    return status
  }

  return (
    <>
      <Main>
        <Grid container spacing={1}>
          <DescriptionItem label="Identificador" value={data.uuid} md={12} />

          <DescriptionItem label="Realizada em" value={data.createdAt} md={4} />
          <DescriptionItem label="Nome do candidato" value={data.name} md={4} />
          <DescriptionItem
            label="Data de nascimento"
            value={data.birthdate}
            md={4}
          />

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <DescriptionItem label="Situação" value={renderStatus()} md={12} />

          {schoolName && (
            <DescriptionItem
              label="Escola de destino"
              value={schoolName}
              md={12}
            />
          )}

          {cityName && (
            <DescriptionItem label="Cidade" value={cityName} md={4} />
          )}

          {shift && <DescriptionItem label="Turno" value={shift} md={2} />}

          {curriculumName && (
            <DescriptionItem
              label="Organização curricular"
              value={curriculumName}
              md={6}
            />
          )}

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          {publishInfo && (
            <>
              <Grid item xs={12}>
                <Subtitle className={classes.subtitle}>Importante</Subtitle>
              </Grid>
              <Grid item xs={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: publishInfo || '--',
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Main>
    </>
  )
}

export default Classification
