import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { strings, errors as errorUtils } from '../../../../../../utils'

import { FormControl } from '../../../../../../components/Forms'
import { Alert } from '../../../../../../components'
import { ZipCodeInput } from '../../../../../../components/Forms/Inputs'

const { limit, keepOnlyNumbers, upcase } = strings

const useStyles = makeStyles(() => ({
  divider: {
    margin: '20px 0',
  },
  alert: {
    margin: '16px 0',
  },
}))

const handleText = text => upcase(limit(text, 250))

const Form = ({ address, errors, validating, update }) => {
  const classes = useStyles()

  const [_errors, _setErrors] = useState({})

  const hasError = errorUtils.hasError(_errors)
  const errorMessage = errorUtils.errorMessage(_errors)

  const shouldDisplayAlert = () => address.weak || !!address.cityZipcode

  const shouldDisableManualInput = () =>
    (!address.weak && !address.cityZipcode) || validating

  const zipCodeLabelStyleColorWorkaround = () =>
    shouldDisableManualInput() ? { color: '#cfcfcf' } : {}

  const manualInputProps = () =>
    shouldDisableManualInput() && address.streetRef ? { shrink: true } : {}

  useEffect(() => {
    _setErrors(errors)
  }, [errors])

  return (
    <>
      <Grid container spacing={1}>
        {shouldDisplayAlert() ? (
          <Grid item xs={12} className={classes.alert}>
            <Alert>
              <strong>Atenção!</strong>
              <br />
              Preencha todas as informações corretamente, pois estes dados serão
              usados para cálculo da distância entre a escola e a residência do
              candidato. Dados incorretos apresentarão resultados imprecisos.
            </Alert>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        )}

        <Grid item md={3} sm={4} xs={12}>
          <FormControl variant="outlined" error={hasError('zipcode')}>
            <InputLabel
              style={zipCodeLabelStyleColorWorkaround()}
              htmlFor="zipCode"
            >
              CEP
            </InputLabel>
            <OutlinedInput
              disabled={shouldDisableManualInput() || !!address.cityZipcode}
              error={hasError('zipcode')}
              label="CEP"
              variant="outlined"
              id="zipCode"
              name="zipCode"
              value={address.zipcode || ''}
              onChange={e => update({ zipcode: e.target.value })}
              inputComponent={ZipCodeInput}
            />

            {hasError('zipcode') && (
              <FormHelperText>{errorMessage('zipcode')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={9} sm={8} xs={12}>
          <FormControl>
            <TextField
              error={hasError('streetStr')}
              helperText={errorMessage('streetStr')}
              InputLabelProps={manualInputProps()}
              disabled={shouldDisableManualInput()}
              label="Rua"
              variant="outlined"
              name="street"
              value={address.street || ''}
              onChange={e => update({ street: handleText(e.target.value) })}
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={8} xs={12}>
          <FormControl>
            <TextField
              error={hasError('neighborhood')}
              helperText={errorMessage('neighborhood')}
              InputLabelProps={manualInputProps()}
              disabled={shouldDisableManualInput()}
              label="Bairro"
              variant="outlined"
              name="neighborhood"
              value={address.neighborhood || ''}
              onChange={e =>
                update({ neighborhood: handleText(e.target.value) })
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={4} xs={12}>
          <FormControl>
            <TextField
              error={hasError('number')}
              helperText={errorMessage('number')}
              disabled={address.withoutNumber || validating}
              label="Número"
              variant="outlined"
              name="number"
              value={address.number || ''}
              onChange={e =>
                update({ number: keepOnlyNumbers(e.target.value) })
              }
            />
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl>
            <TextField
              disabled={validating}
              label="Complemento (opcional)"
              variant="outlined"
              name="complement"
              value={address.complement || ''}
              onChange={e => update({ complement: handleText(e.target.value) })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={address.withoutNumber || false}
                disabled={validating}
                onChange={e => {
                  let hash = { ..._errors }

                  if (e.target.checked) {
                    delete hash['number']
                  } else if (errors.number) {
                    hash = { ...hash, number: errors.number }
                  }

                  _setErrors(hash)

                  update({ withoutNumber: e.target.checked, number: undefined })
                }}
                name="withoutNumber"
                color="primary"
              />
            }
            label="Endereço não possui número"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Form
