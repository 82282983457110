import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import {
  Alert,
  DescriptionItem,
  Label,
  Subtitle,
} from '../../../../../../components'

import { enums } from '../../../../../../utils'

const { disabilities, disorders, giftedness, findByValue } = enums

const useStyles = makeStyles(() => ({
  alert: {
    margin: '8px 0 4px',
  },
  label: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
  divider: {
    margin: '16px 0',
  },
  ul: {
    margin: '8px 0',
    paddingLeft: '24px',
  },
}))

const Disabilities = ({ student, items }) => {
  const classes = useStyles()

  const _disabilities = disabilities.filter(i => items.includes(i.value))
  const _disorders = disorders.filter(i => items.includes(i.value))
  const _giftedness = giftedness.filter(i => items.includes(i.value))

  return (
    <>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12}>
        <Subtitle>Condição do candidato</Subtitle>
      </Grid>

      <DescriptionItem
        label="Possui deficiência, transtorno do espectro autista ou altas habilidades/superdotação?"
        value={findByValue('trueAndFalse', student.hasDisability)}
        sm={12}
        md={12}
      />

      {items.length > 0 && (
        <Grid item xs={12} className={classes.alert}>
          <Alert>
            Caso o candidato seja contemplado, será obrigatório apresentação de
            laudo médico comprobatório no ato da efetivação da matrícula.
          </Alert>
        </Grid>
      )}

      {_disabilities.length > 0 && (
        <Grid item md={4} xs={12}>
          <Label variant="subtitle1" className={classes.label}>
            Deficiência
          </Label>

          <ul className={classes.ul}>
            {_disabilities.map(i => (
              <li key={i.value}>{i.label}</li>
            ))}
          </ul>
        </Grid>
      )}

      {_disorders.length > 0 && (
        <Grid item md={4} xs={12}>
          <Label variant="subtitle1" className={classes.label}>
            Transtorno global do desenvolvimento
          </Label>

          <ul className={classes.ul}>
            {_disorders.map(i => (
              <li key={i.value}>{i.label}</li>
            ))}
          </ul>
        </Grid>
      )}

      {_giftedness.length > 0 && (
        <Grid item md={4} xs={12}>
          <Label variant="subtitle1" className={classes.label}>
            Altas habilidades/Superdotação
          </Label>

          <ul className={classes.ul}>
            {_giftedness.map(i => (
              <li key={i.value}>{i.label}</li>
            ))}
          </ul>
        </Grid>
      )}
    </>
  )
}

export default Disabilities
