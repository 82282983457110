import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '20px 0',
  },
}))

const DividerComponent = ({ ...rest }) => {
  const classes = useStyles()

  return <Divider className={classes.divider} {...rest} />
}

export default DividerComponent
