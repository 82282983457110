import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import { Main } from '../../../../components/Layout'
import { FormControl } from '../../../../components/Forms'
import { BirthdateInput, CpfInput } from '../../../../components/Forms/Inputs'
import {
  Error,
  LoadingButton,
  Recaptcha,
  Subtitle,
} from '../../../../components'
import { Buttons } from '../../../../components/Steps'

const useStyles = makeStyles(() => ({
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: '24px 0 0',
  },
  error: {
    marginBottom: '16px',
  },
  margin: {
    marginTop: '12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
}))

const Form = ({ loading, notFound, tryToSearch }) => {
  const classes = useStyles()

  const [recaptcha, setRecaptcha] = useState('preenchido')
  const [cpf, setCpf] = useState('')
  const [birthdate, setBirthdate] = useState('')

  const shouldDisableSearchButton = () =>
    !recaptcha ||
    `${cpf}`.length !== 14 ||
    `${birthdate}`.length !== 10 ||
    loading

  return (
    <>
      <Main className={classes.main}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle>
              Preencha os campos abaixo com dados do aluno para realizar a
              solicitação de rematrícula para o próximo ano letivo
            </Subtitle>
          </Grid>

          {notFound && (
            <Grid item xs={12} className={classes.error}>
              <Error>
                Não foi possível encontrar nenhuma matrícula ativa para o aluno.
                Verifique os dados inseridos tente novamente.
              </Error>
            </Grid>
          )}

          <Grid item md={6} xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="cpf">CPF do aluno</InputLabel>
              <OutlinedInput
                autoFocus
                disabled={loading}
                label="CPF do aluno"
                variant="outlined"
                id="cpf"
                name="cpf"
                value={cpf}
                onChange={e => setCpf(e.target.value)}
                inputComponent={CpfInput}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="birthdate">
                Data de nascimento do aluno
              </InputLabel>
              <OutlinedInput
                disabled={loading}
                label="Data de nascimento do aluno"
                variant="outlined"
                id="birthdate"
                name="birthdate"
                value={birthdate || ''}
                onChange={e => setBirthdate(e.target.value)}
                inputComponent={BirthdateInput}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Recaptcha onChange={setRecaptcha} />
          </Grid>
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        <LoadingButton
          className={classes.margin}
          disabled={shouldDisableSearchButton()}
          loading={loading}
          onClick={tryToSearch(cpf, birthdate)}
        >
          Consultar
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Form
