import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(() => ({
  typo: {
    color: '#797543',
  },
  alert: {
    backgroundColor: '#fff3cd',
    padding: '1rem',
  },
}))

const Alert = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Paper elevation={1} className={classes.alert}>
      <Typography className={classes.typo} variant="subtitle2" {...rest}>
        {children}
      </Typography>
    </Paper>
  )
}

export default Alert
