import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { Error, Subtitle } from '../../../../../../components'
import { errors as errorUtils, options } from '../../../../../../utils'
import { intentService } from '../../../../../../services'

import Option from './Option'

const { hasError, errorMessage } = errorUtils
const { updateOpt, questionsFor, optComplete } = options

const useStyles = makeStyles(() => ({
  marginTop: { marginTop: '11px' },
  error: { marginBottom: '16px' },
}))

const Options = ({
  degrees,
  errors,
  intent,
  loading,
  validating,
  setLoading,
  update,
}) => {
  const classes = useStyles()

  const { optOne, optTwo, optThree } = intent

  const [questions, setQuestions] = useState([])

  const _optComplete = optComplete(questions)
  const _questionsFor = questionsFor(questions)
  const _updateOpt = updateOpt(update, questions)

  const exceptOfferIdsForOptThree = () =>
    [optOne.offerId, optTwo.offerId].filter(i => !isNaN(i)).join(',')

  const tryToGetQuestions = async () => {
    setLoading(true)

    const {
      success,
      payload: { questions: _questions },
    } = await intentService.getSchoolsQuestions()

    if (success) {
      setQuestions(_questions)
    }

    setLoading(false)
  }

  useEffect(() => {
    tryToGetQuestions()
  }, [])

  const renderErrors = () => {
    if (hasError(errors)('intents.degree')) {
      return (
        <Grid item xs={12} className={classes.error}>
          <Error>
            <strong>Uma ou mais opções contém erros:</strong>
            <br />
            <span>{errorMessage(errors)('intents.degree')}</span>
          </Error>
        </Grid>
      )
    }

    return <></>
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.marginTop}>
        <Subtitle>
          Escolha até três opções de escolas que são de interesse do candidato.
          Todos os campos devem ser preenchidos.
        </Subtitle>
      </Grid>

      {renderErrors()}

      <Option
        degrees={degrees}
        errors={errors}
        validating={validating}
        divider
        label="Primeira (obrigatória)"
        loading={loading}
        option={optOne}
        prevOptIsCompleted={true}
        questions={_questionsFor({ optOne })}
        setLoading={setLoading}
        update={_updateOpt({ optOne })}
      />

      <Option
        degrees={degrees}
        errors={errors}
        exceptOfferIds={optOne.offerId || ''}
        validating={validating}
        divider
        label="Segunda (opcional)"
        loading={loading}
        option={optTwo}
        prevOptIsCompleted={_optComplete({ optOne })}
        questions={_questionsFor({ optTwo })}
        setLoading={setLoading}
        update={_updateOpt({ optTwo })}
      />

      <Option
        degrees={degrees}
        errors={errors}
        exceptOfferIds={exceptOfferIdsForOptThree()}
        validating={validating}
        label="Terceira (opcional)"
        loading={loading}
        option={optThree}
        prevOptIsCompleted={
          _optComplete({ optOne }) && _optComplete({ optTwo })
        }
        questions={_questionsFor({ optThree })}
        setLoading={setLoading}
        update={_updateOpt({ optThree })}
      />
    </Grid>
  )
}

export default Options
