import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

// import { constants } from '../../../../../utils' pegar aqui os valores default

import { Editing, LoadingButton, StepHint } from '../../../../../components'
import { Main } from '../../../../../components/Layout'
import { Buttons } from '../../../../../components/Steps'

import Form from './elements/Form'

const useStyles = makeStyles(() => ({
  end: {
    justifyContent: 'flex-end',
  },
}))

const Students = ({ data, errors, persist, validating }) => {
  const classes = useStyles()

  const [student, setStudent] = useState({ ...data })

  const update = attr => {
    setStudent({ ...student, ...attr })
  }

  return (
    <>
      <StepHint>
        Todos os campos devem ser preenchidos com dados do aluno candidato
      </StepHint>

      <Main>
        <Editing />

        <Form
          errors={errors}
          student={student}
          update={update}
          validating={validating}
        />
      </Main>

      <Buttons extraClasses={classes.end}>
        <LoadingButton
          disabled={validating}
          loading={validating}
          onClick={persist({ student })}
        >
          Próxima
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Students
