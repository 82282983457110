import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import { Main, PageHeader } from '../components/Layout'
import { Recaptcha, Subtitle } from '../components'
import { Buttons } from '../components/Steps'
import { constants } from '../utils'
import { appService } from '../services'

const useStyles = makeStyles(() => ({
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: '24px 0 0',
  },
  margin: {
    marginTop: '12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
}))

const Instructions = () => {
  const classes = useStyles()
  const history = useHistory()

  const [recaptcha, setRecaptcha] = useState('')

  useEffect(() => {
    if (appService.shouldRedirectToHome()) {
      history.push(constants.routes.home.path)
    }
  }, [])

  return (
    <>
      <PageHeader title="Instruções iniciais" />

      <Main className={classes.main}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle>
              Antes de iniciar a inscrição, leia atentamente as informações a
              seguir:
            </Subtitle>
          </Grid>

          <Grid item xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: appService.getLocalPublicCall().instructions || '--',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Recaptcha onChange={setRecaptcha} />
          </Grid>
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        <Button
          className={classes.margin}
          disabled={!recaptcha}
          variant="contained"
          color="primary"
          onClick={() =>
            history.push(constants.routes.subscriptions.create.path)
          }
        >
          Iniciar inscrição
        </Button>

        <Button
          className={classes.margin}
          variant="contained"
          color="default"
          onClick={() => history.push(constants.routes.home.path)}
        >
          Sair
        </Button>
      </Buttons>
    </>
  )
}

export default Instructions
