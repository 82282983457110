import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'

import Routes from './routes'
import { colors } from './utils'

const App = () => (
  <ThemeProvider theme={colors.theme}>
    <CssBaseline />
    <Routes />
  </ThemeProvider>
)

export default App
