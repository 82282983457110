const handleAddress = a => ({
  cityId: a.cityId,
  streetId: a.streetId,
  weak: a.weak,
  zipcode: a.zipcode,
  streetStr: a.street,
  neighborhood: a.neighborhood,
  number: a.number,
  withoutNumber: a.withoutNumber,
  complement: a.complement,
})

const handleResponsible = r => ({
  rKind: r.kind,
  rName: r.name,
  rCpf: r.cpf,
  rMobile: r.mobile,
  rAltPhone: r.altPhone,
  rEmail: r.email,
  rNewsletter: r.newsletter,
})

// necessario tratar as options com undescore pois o hash.transform nao faz no nested

const optEnum = {
  optOne: 'one',
  optTwo: 'two',
  optThree: 'three',
}

const handleIntentAnswers = opt =>
  (opt.answers || []).map(i => ({
    question_id: i.questionId,
    answer_id: i.answerId,
    extra: i.extra,
  }))

const handleIntents = i => ({
  intentsAttributes: ['optOne', 'optTwo', 'optThree']
    .filter(opt => !!(i[opt] || {}).completed)
    .map(opt => ({
      kind: optEnum[opt],
      degree_ref: i[opt].degreeRef,
      city_ref: i[opt].cityRef,
      school_ref: i[opt].schoolRef,
      shift: i[opt].shift,
      curriculum_ref: i[opt].curriculumRef,
      offer_id: i[opt].offerId,
      answers_attributes: handleIntentAnswers(i[opt]),
    })),
})

const handleQuizzes = i => ({
  answersAttributes: (i.answers || []).map(a => ({
    question_id: a.questionId,
    answer_id: a.answerId,
    extra: a.extra,
  })),
})

const prepareToApi = (
  { student, address: a, responsible: r, intent: i, quiz: q, version },
  step = undefined
) => ({
  step,
  version,
  ...student,
  ...handleAddress(a),
  ...handleResponsible(r),
  ...handleIntents(i),
  ...handleQuizzes(q),
})

const params = { prepareToApi }
export default params
