import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { LoadingButton } from '../../../../components'
import { Main } from '../../../../components/Layout'
import { Buttons } from '../../../../components/Steps'
import { confirmationService, subscriptionService } from '../../../../services'

import {
  Address,
  Intent,
  Quiz,
  Responsible,
  Student,
} from '../../Create/Steps/Confirmation/elements'

import Info from './Info'
import Update from './Update'

const useStyles = makeStyles(() => ({
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const Confirmation = ({ data }) => {
  const classes = useStyles()

  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(false)

  const intentQuestions = () => questions.filter(i => i.kind === 'school')
  const quizQuestions = () => questions.filter(i => i.kind === 'subscription')

  const tryToGetQuestions = async () => {
    const {
      success,
      payload: { questions: _questions },
    } = await confirmationService.getQuestions()

    if (success) {
      setQuestions(_questions)
    }
  }

  const tryToGetPdf = async () => {
    setLoading(true)

    await subscriptionService.getPdf(data.uuid)

    setLoading(false)
  }

  useEffect(() => {
    tryToGetQuestions()
  }, [])

  return (
    <>
      <Main>
        <Grid container spacing={1}>
          <Info data={data} />
          <Student student={data.student} />
          <Address address={data.address} />
          <Responsible responsible={data.responsible} student={data.student} />
          <Intent intent={data.intent} questions={intentQuestions()} />

          <Quiz
            questions={quizQuestions()}
            quiz={data.quiz}
            divider={data.canUpdate}
          />

          {data.canUpdate && <Update data={data} />}
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        <LoadingButton
          disabled={loading}
          loading={loading}
          onClick={tryToGetPdf}
        >
          Reimprimir Comprovante da Inscrição
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Confirmation
