const hasError = errors => attr => attr in errors

const errorMessage = errors => attr => {
  try {
    const message = errors[attr].join(', ')
    return message.charAt(0).toUpperCase() + message.slice(1)
  } catch {
    return null
  }
}

const errorFromMessages = (errors, attr, partial) => {
  try {
    return errors[attr].find(i => i.includes(partial))
  } catch {
    return ''
  }
}

const errors = { hasError, errorMessage, errorFromMessages }
export default errors
