import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'

import { Buttons } from '../../../../components/Steps'
import { Error, Subtitle, LoadingButton } from '../../../../components'
import { FormControl } from '../../../../components/Forms'
import { Main } from '../../../../components/Layout'
import { renewEnrollmentService } from '../../../../services'

const useStyles = makeStyles(() => ({
  alert: {
    marginBottom: '15px',
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: '20px 0 25px',
  },
  error: { marginBottom: '16px' },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
  formControl: {
    width: '100%',
    marginTop: '25px',
  },
}))

const Renewing = ({ data, setDone, setEnrollment }) => {
  const classes = useStyles()

  const [curriculum, setCurriculum] = useState(null)
  const [degree, setDegree] = useState(null)
  const [degrees, setDegrees] = useState([])
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [submiting, setSubmiting] = useState(null)

  const shouldDisplayRequestButton = () => degrees.length > 0

  const shouldDisableRequestButton = () =>
    loading || submiting || !degree || !curriculum

  useEffect(() => {
    tryToGetNextOffers()
  }, [])

  const tryToGetNextOffers = async () => {
    setLoading(true)

    const { success, payload } = await renewEnrollmentService.nextOffers(
      data.id
    )

    if (success) {
      setDegrees(payload)
      setNotFound(payload.length < 1)

      if (payload.length === 1) {
        setDegree(payload[0].id)
      }
    } else {
      setNotFound(true)
    }

    setLoading(false)
  }

  const tryToSubmit = async () => {
    setSubmiting(true)

    const { success, payload, error } =
      await renewEnrollmentService.tryToSubmit(data.id, degree, curriculum)

    if (success) {
      setDone(true)
      setEnrollment(payload)
    } else {
      setError(error)
    }

    setSubmiting(false)
  }

  const availableCurriculumsForDegree = () => {
    try {
      return degrees.find(i => i.id === degree).curriculums
    } catch {
      return []
    }
  }

  const renderError = message => (
    <Grid item xs={12} className={degrees.length > 0 ? classes.error : false}>
      <Error>
        <span>{message}</span>
      </Error>
    </Grid>
  )

  const renderBody = () => {
    if (notFound) {
      return renderError(
        data.noOfferMessage ||
          'Não foi possível encontrar nenhuma oferta disponível, consulte a Secretaria de Educação para ajuda.'
      )
    } else {
      return (
        <>
          {!!error && renderError(error)}

          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="degree">
                Ano de escolaridade a ser cursado
              </InputLabel>
              <Select
                autoFocus={degrees.length > 1}
                disabled={loading || submiting}
                label="Ano de escolaridade a ser cursado"
                id="degree"
                value={degree}
                onChange={e => {
                  setCurriculum(null)
                  setDegree(e.target.value)
                }}
              >
                {degrees.map(i => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="curriculum">
                Organização curricular
              </InputLabel>
              <Select
                autoFocus={degrees.length === 1 && !curriculum}
                disabled={loading || submiting || !degree}
                label="Organização curricular"
                id="curriculum"
                value={curriculum}
                onChange={e => setCurriculum(e.target.value)}
              >
                {availableCurriculumsForDegree().map(i => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )
    }
  }

  const renderLoadingOrBody = () =>
    loading ? <CircularProgress size={20} /> : renderBody()

  return (
    <>
      <Main className={classes.main}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle>Detalhes da renovação da matrícula</Subtitle>
          </Grid>

          {renderLoadingOrBody()}
        </Grid>
      </Main>

      {shouldDisplayRequestButton() && (
        <Buttons extraClasses={classes.center}>
          <LoadingButton
            disabled={shouldDisableRequestButton()}
            loading={submiting}
            onClick={tryToSubmit}
          >
            Confirmar solicitação
          </LoadingButton>
        </Buttons>
      )}
    </>
  )
}

export default Renewing
