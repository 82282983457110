import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Student,
  Address,
  Responsible,
  Intent,
  Quiz,
  Confirmation,
  Finished,
} from './Steps'

import { constants } from '../../../utils'
import { PageHeader } from '../../../components/Layout'
import { Progress } from '../../../components'
import { appService, subscriptionService } from '../../../services'

const getInitialData = () => {
  const { initialData } = window

  if (initialData) {
    try {
      return JSON.parse(initialData)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    student: {},
    address: {},
    responsible: { newsletter: true },
    intent: {
      optOne: {},
      optTwo: {},
      optThree: {},
    },
    quiz: {},
  }
}

const Create = () => {
  const history = useHistory()

  const [confirmation, setConfirmation] = useState({})
  const [count, setCount] = useState(0)
  const [step, setStep] = useState(0)
  const [subscription, setSubscription] = useState(getInitialData())
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [validating, setValidating] = useState(false)

  const next = () => setStep(step + 1)
  const prev = () => setStep(step - 1)

  const persistSubscription = attr => () => {
    setSubscription({ ...subscription, ...attr })
  }

  const tryToValidate = async () => {
    setValidating(true)

    const { success, payload } = await subscriptionService.sendToValidate(
      subscription,
      step
    )

    setErrors(payload.errors)
    setValidating(false)

    if (success) {
      next()
    }
  }

  const tryToSubmit = async () => {
    setSubmitting(true)

    const { success, payload } = await subscriptionService.tryToSubmit(
      subscription
    )

    setSubmitting(false)

    if (success) {
      setConfirmation(payload.confirmation)
    } else {
      setErrors(payload.errors)
    }
  }

  useEffect(() => {
    if (appService.shouldRedirectToHome()) {
      history.push(constants.routes.home.path)
    }
  }, [])

  /*
    workaround por conta de não ter um callback para garantir o update e o
    useEffect executar logo no primeiro render
  */

  useEffect(() => {
    if (count > 0 && step !== 5) {
      tryToValidate()
    } else {
      setCount(count + 1)
    }
  }, [subscription])

  useEffect(() => {
    if (step === 5) {
      next()
    }
  }, [confirmation])

  const steps = [
    'Dados do candidato',
    'Endereço',
    'Dados do responsável',
    'Escolas e quadros curriculares',
    'Questionário',
    'Confirmação dos dados',
    'Inscrição realizada',
  ]

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Student
            data={subscription.student}
            errors={errors}
            persist={persistSubscription}
            validating={validating}
          />
        )
      case 1:
        return (
          <Address
            data={subscription.address}
            errors={errors}
            persist={persistSubscription}
            prev={prev}
            validating={validating}
          />
        )
      case 2:
        return (
          <Responsible
            data={subscription}
            errors={errors}
            persist={persistSubscription}
            prev={prev}
            validating={validating}
          />
        )
      case 3:
        return (
          <Intent
            data={subscription}
            errors={errors}
            persist={persistSubscription}
            prev={prev}
            validating={validating}
          />
        )
      case 4:
        return (
          <Quiz
            data={subscription.quiz}
            errors={errors}
            persist={persistSubscription}
            prev={prev}
            validating={validating}
          />
        )
      case 5:
        return (
          <Confirmation
            submitting={submitting}
            data={subscription}
            prev={prev}
            tryToSubmit={tryToSubmit}
          />
        )
      default:
        return <Finished data={confirmation} student={subscription.student} />
    }
  }

  const shouldDisplayProgress = () => step + 1 < steps.length

  const lastStep = () => step + 1 >= steps.length

  const backButtonTitle = () => {
    if (lastStep()) {
      return null
    }

    return subscription.uuid ? 'Cancelar atualização' : 'Cancelar'
  }

  return (
    <>
      <PageHeader
        buttonText={backButtonTitle()}
        disabled={validating || submitting}
        title={steps[step]}
        onClick={
          lastStep() ? null : () => history.push(constants.routes.home.path)
        }
      />

      {shouldDisplayProgress() && (
        <Progress steps={steps.length} value={step} />
      )}

      {renderStep()}
    </>
  )
}

export default Create
