import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  typo: {
    marginBottom: '4px',
  },
}))

const Title = ({ children, ...rest }) => (
  <Typography className={useStyles().typo} variant="body1" {...rest}>
    {children}
  </Typography>
)

export default Title
