import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { PageHeader } from '../../../components/Layout'
import { constants } from '../../../utils'
import { appService, renewEnrollmentService } from '../../../services'

import { Renewing, Done, Form, Found, Selection } from './elements'

const Search = () => {
  const history = useHistory()

  const [fetched, setFetched] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(false)
  const [enrollments, setEnrollments] = useState([])
  const [enrollment, setEnrollment] = useState(null)
  const [renewing, setRenewing] = useState(null)
  const [done, setDone] = useState(false)

  useEffect(() => {
    if (appService.shouldRedirectToHome()) {
      history.push(constants.routes.home.path)
    }
  }, [])

  useEffect(() => {
    if (enrollments.length === 1 && !enrollment) {
      setEnrollment(enrollments[0])
    }
  }, [enrollments])

  const backToEnrollment = () => {
    setDone(false)
    setRenewing(false)
  }

  const tryToSearch = (cpf, birthdate) => async () => {
    setLoading(true)

    const { success, payload } = await renewEnrollmentService.get(
      cpf,
      birthdate
    )

    if (success) {
      setEnrollments(payload)
      setFetched(true)
      setNotFound(false)
    } else {
      setNotFound(true)
    }

    setLoading(false)
  }

  const renderFormOrEnrollmentSelection = () =>
    fetched ? (
      <Selection data={enrollments} setEnrollment={setEnrollment} />
    ) : (
      <Form loading={loading} notFound={notFound} tryToSearch={tryToSearch} />
    )

  const renderFoundOrRenewing = () => {
    if (done) {
      return <Done data={enrollment} backToEnrollment={backToEnrollment} />
    } else if (renewing) {
      return (
        <Renewing
          data={enrollment}
          setDone={setDone}
          setEnrollment={setEnrollment}
        />
      )
    }

    return (
      <Found
        data={enrollment}
        done={done}
        setEnrollment={setEnrollment}
        setRenewing={setRenewing}
      />
    )
  }

  return (
    <>
      <PageHeader
        buttonText="Voltar"
        title="Solicitar renovação de matrícula"
        onClick={
          done
            ? false
            : () => {
                if (renewing) {
                  setRenewing(false)
                } else if (enrollment) {
                  setEnrollment(null)

                  if (enrollments.length < 2) {
                    setEnrollments([])
                    setFetched(false)
                  }
                } else {
                  history.push(constants.routes.home.path)
                }
              }
        }
      />

      {enrollment ? renderFoundOrRenewing() : renderFormOrEnrollmentSelection()}
    </>
  )
}

export default Search
