import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

import { strings } from '../../../../../../utils'

import Basic from './Basic'
import Disability from './Disability'
import Study from './Study'

const { keepOnlyUpcaseLettersAndBlankSpace, limit, upcase } = strings

const handleText = text =>
  keepOnlyUpcaseLettersAndBlankSpace(upcase(limit(text, 100)))

const useStyles = makeStyles(() => ({
  marginTop: { marginTop: '20px' },
  divider: {
    margin: '16px 0 4px',
  },
  alert: {
    margin: '8px 0',
  },
  subtitleMargin: {
    fontWeight: 600,
    margin: '8px 0 0',
  },
  checkbox: {
    padding: '3px',
    margin: '0 6px',
  },
  checkboxGrid: {
    paddingBottom: '0 !important',
  },
}))

const Info = ({ errors, student, update, validating }) => {
  const classes = useStyles()

  return (
    <>
      <Basic
        errors={errors}
        handleText={handleText}
        validating={validating}
        student={student}
        update={update}
      />

      <Study
        classes={classes}
        errors={errors}
        validating={validating}
        student={student}
        update={update}
      />

      <Disability
        classes={classes}
        errors={errors}
        validating={validating}
        student={student}
        update={update}
      />
    </>
  )
}

export default Info
