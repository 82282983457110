import { publicCallApi } from '../api'

const setLocalPublicCall = info =>
  window.localStorage.setItem('cpe-info', JSON.stringify(info))

const getLocalPublicCall = () => {
  try {
    return JSON.parse(window.localStorage.getItem('cpe-info')) || {}
  } catch {
    return {}
  }
}

const shouldRedirectToHome = () => !getLocalPublicCall().name

const getPublicCallInfo = async () => {
  try {
    const response = await publicCallApi.getInfo()

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const info = responseBody.data.attributes

      setLocalPublicCall(info)

      return {
        success: true,
        payload: { info },
      }
    }
  } catch {}

  return {
    success: false,
    payload: {
      info: {},
    },
  }
}

const infoService = {
  getPublicCallInfo,
  getLocalPublicCall,
  shouldRedirectToHome,
}

export default infoService
