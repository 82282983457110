import { useHistory } from 'react-router-dom'
import AssignmentIcon from '@material-ui/icons/Assignment'
import HomeIcon from '@material-ui/icons/Home'
import AddBoxIcon from '@material-ui/icons/AddBox'
import RefreshIcon from '@material-ui/icons/Refresh'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'

import DrawerIcon from './DrawerIcon'

import { appService } from '../../../services'
import { constants } from '../../../utils'

const DrawerItems = ({ onClick }) => {
  const history = useHistory()

  return (
    <>
      <List>
        <ListItem
          button
          key={constants.routes.home.key}
          onClick={() => {
            onClick()
            history.push(constants.routes.home.path)
          }}
        >
          <DrawerIcon>
            <HomeIcon />
          </DrawerIcon>

          <ListItemText primary={constants.routes.home.title} />
        </ListItem>

        {appService.getLocalPublicCall().renewEnrollment && (
          <ListItem
            button
            key={constants.routes.renewEnrollments.request.key}
            onClick={() => {
              onClick()
              history.push(constants.routes.renewEnrollments.request.path)
            }}
          >
            <DrawerIcon>
              <RefreshIcon />
            </DrawerIcon>

            <ListItemText
              primary={constants.routes.renewEnrollments.request.title}
            />
          </ListItem>
        )}

        {appService.getLocalPublicCall().subscribe && (
          <>
            <ListItem
              button
              key={constants.routes.subscriptions.create.key}
              onClick={() => {
                onClick()
                history.push(constants.routes.instructions.path)
              }}
            >
              <DrawerIcon>
                <AddBoxIcon />
              </DrawerIcon>

              <ListItemText
                primary={constants.routes.subscriptions.create.title}
              />
            </ListItem>

            <ListItem
              button
              key={constants.routes.subscriptions.search.key}
              onClick={() => {
                onClick()
                history.push(constants.routes.subscriptions.search.path)
              }}
            >
              <DrawerIcon>
                <AssignmentIcon />
              </DrawerIcon>

              <ListItemText
                primary={constants.routes.subscriptions.search.title}
              />
            </ListItem>
          </>
        )}
      </List>
    </>
  )
}

export default DrawerItems
