import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { DescriptionItem, Subtitle } from '../../../../../../components'

import { enums } from '../../../../../../utils'

import Disabilities from './Disabilities'

const { findByValue } = enums

const useStyles = makeStyles(() => ({
  divider: {
    margin: '16px 0',
  },
}))

const Student = ({ student }) => (
  <>
    <Grid item xs={12}>
      <Subtitle>Dados do candidato</Subtitle>
    </Grid>

    <DescriptionItem
      label="Nome completo"
      value={student.name}
      md={student.hasSocialName ? 6 : 12}
    />

    {student.hasSocialName && (
      <DescriptionItem label="Nome social" value={student.socialName} md={6} />
    )}

    <DescriptionItem label="CPF" value={student.cpf} />
    <DescriptionItem label="Data de nascimento" value={student.birthdate} />

    <DescriptionItem
      label="Sexo"
      value={findByValue('genders', student.gender)}
    />

    <DescriptionItem
      label="Possui irmão gêmeo?"
      value={findByValue('trueAndFalse', student.twin)}
    />

    <DescriptionItem
      label="Filiação 1 (preferencialmente mãe)"
      value={student.parentOne || 'NÃO INFORMADO'}
      md={6}
    />

    <DescriptionItem
      label="Filiação 2 (preferencialmente pai)"
      value={student.parentTwo || 'NÃO INFORMADO'}
      md={6}
    />

    <DescriptionItem
      label="Local atual de estudo"
      value={findByValue('studyPlaces', student.studyPlace)}
      md={12}
      sm={12}
    />

    {student.hasDisability && (
      <Disabilities items={student.disabilities} student={student} />
    )}

    <Grid item xs={12}>
      <Divider className={useStyles().divider} />
    </Grid>
  </>
)

export default Student
