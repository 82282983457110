import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
    minHeight: '54px',
  },
  title: {
    lineHeight: '1rem',
    maxWidth: '55%',
  },
}))

const PageHeader = ({
  extraClasses = '',
  title,
  buttonText,
  onClick,
  disabled,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${extraClasses}`} {...rest}>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      {!!onClick && (
        <Button
          className="no-print"
          color="secondary"
          disabled={disabled}
          onClick={onClick}
          variant="contained"
        >
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default PageHeader
