import Grid from '@material-ui/core/Grid'
import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'

import { strings } from '../../utils'

import { FormControl } from '../../components/Forms'

const { limit, keepOnlyNumbers, upcase } = strings

const useStyles = makeStyles(() => ({
  container: {
    margin: '8px',
    marginBottom: '8px',
    width: '100%',
  },
  legend: {
    marginBottom: '8px',
  },
  formControl: {
    width: '100%',
  },
  controlLabel: {
    '& .MuiRadio-root': {
      padding: '4px 8px',
    },
  },
  text: {
    width: '100%',
    marginTop: '8px',
  },
  number: {
    width: '33.333%',
    marginTop: '8px',
  },
}))

const handleText = text => upcase(limit(text, 250))

const Question = ({ disabled, question, setAnswer, validating }) => {
  const classes = useStyles()

  const { answerId, extra, id, title, options = [] } = question || {}

  const sortedOptions = () =>
    options.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))

  const renderAnswerExtra = () => {
    const answeredOption = options.find(i => i.id === answerId) || {}

    if (['text', 'number'].includes(answeredOption.kind)) {
      return (
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              className={
                answeredOption.kind === 'text' ? classes.text : classes.number
              }
              disabled={disabled || validating}
              label="Resposta"
              variant="outlined"
              name={`extra-text-${id}`}
              value={extra === 0 ? 0 : extra || ''}
              onChange={e =>
                answeredOption.kind === 'text'
                  ? setAnswer(answerId, handleText(e.target.value))
                  : setAnswer(
                      answerId,
                      parseInt(keepOnlyNumbers(e.target.value))
                    )
              }
            />
          </FormControl>
        </Grid>
      )
    }

    return <></>
  }

  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <FormLabel component="legend" className={classes.legend}>
            {title}
          </FormLabel>

          <RadioGroup
            aria-label="quiz"
            name={`question-${id}`}
            value={answerId || ''}
            onChange={e => setAnswer(parseInt(e.target.value), undefined)}
          >
            {sortedOptions().map(opt => (
              <FormControlLabel
                disabled={disabled || validating}
                className={classes.controlLabel}
                key={opt.id}
                value={opt.id}
                control={<Radio />}
                label={opt.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>

      {!!answerId && renderAnswerExtra()}
    </div>
  )
}

export default Question
