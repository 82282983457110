import React from 'react'

import Question from './Question'

const Builder = ({ questions = [], setAnswer, disableQuestions, validating }) =>
  questions.map(i => (
    <Question
      disabled={disableQuestions}
      key={i.id}
      question={i}
      validating={validating}
      setAnswer={setAnswer(i.id)}
    />
  ))

export default Builder
