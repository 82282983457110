import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { DescriptionItem, Subtitle } from '../../../../../../components'

const useStyles = makeStyles(() => ({
  divider: {
    margin: '16px 0',
  },
}))

const Address = ({ address }) => (
  <>
    <Grid item xs={12}>
      <Subtitle>Endereço do candidato</Subtitle>
    </Grid>

    <DescriptionItem label="UF" value={address.stateAcronym} md={3} sm={6} />
    <DescriptionItem label="Cidade" value={address.cityName} md={9} sm={6} />

    <DescriptionItem label="CEP" value={address.zipcode} md={3} sm={4} />
    <DescriptionItem label="Rua" value={address.street} md={9} sm={8} />

    <DescriptionItem label="Bairro" value={address.neighborhood} />

    <DescriptionItem
      label="Número"
      value={address.withoutNumber ? 'SEM NÚMERO' : address.number}
    />

    <DescriptionItem
      label="Complemento"
      value={address.complement || '--'}
      md={6}
    />

    <Grid item xs={12}>
      <Divider className={useStyles().divider} />
    </Grid>
  </>
)

export default Address
