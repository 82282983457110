import { constants } from '../utils'

const baseUrl = () => {
  switch (window.location.host) {
    case process.env.REACT_APP_PRD:
      return process.env.REACT_APP_PRD_API
    case process.env.REACT_APP_DEV:
      return process.env.REACT_APP_DEV_API
    case 'chamadaescolar.sedu.es.gov.br':
      return 'https://chamadaescolaradm.sedu.es.gov.br'
    default:
      return 'http://console.cpe.es:3002'
  }
}

const headers = (useToken = true) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  }

  if (useToken) headers['Authorization'] = 'get token from anywhere if needed'

  return headers
}

const url = endpoint => baseUrl() + constants.api.currentVersion + endpoint

const base = { headers, url }
export default base
