import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ReCAPTCHA from 'react-google-recaptcha'

import { constants } from '../utils'

const useStyles = makeStyles(() => ({
  recaptchaContainer: {
    width: '100%',
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const Recaptcha = ({ onChange }) => (
  <Grid item className={useStyles().recaptchaContainer}>
    <ReCAPTCHA onChange={onChange} sitekey={constants.params.recaptcha} />
  </Grid>
)

export default Recaptcha
