import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { useHistory } from 'react-router-dom'

import { Label, Subtitle } from '../../../../components'
import { constants } from '../../../../utils'

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '16px',
  },
  subtitle: {
    fontWeight: 600,
    margin: '0px',
  },
  button: {
    marginTop: '16px',
  },
}))

const Info = ({ data }) => {
  const classes = useStyles()
  const history = useHistory()

  const [agreed, setAgreed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (loading) return

    setOpen(false)
  }

  const sendToUpdate = () => {
    setLoading(true)

    window.initialData = JSON.stringify(data)
    history.push(constants.routes.subscriptions.create.path)
  }

  return (
    <>
      <Grid item xs={12} className={classes.marginTop}>
        <Subtitle className={classes.subtitle}>Atualização de dados</Subtitle>
      </Grid>

      <Grid item xs={12}>
        <Label>
          Caso alguma informação esteja incorreta, faltante ou algo tenha mudado
          desde a inscrição, você pode realizar a alteração/atualização dos
          dados.
        </Label>

        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Atualizar inscrição
        </Button>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Deseja seguir com a alteração/atualização?</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Ao prosseguir com a alteração/atualização dos dados, você irá
            repassar por todas as etapas da inscrição, os dados serão
            atualizados e todas as alterações realizadas serão armazenadas.
            <br />
            <br />
            Lembre-se de que todas as informações devem ser verídicas, estarem
            de acordo com a situação do candidato e qualquer informação ou
            documento falso, tornará nulo o cadastro efetuado.
          </DialogContentText>

          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                disabled={loading}
                onChange={e => setAgreed(e.target.checked)}
                name="agreed"
                color="primary"
              />
            }
            label="Li e estou de acordo"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Desistir
          </Button>

          <Button
            color="primary"
            disabled={loading || !agreed}
            onClick={sendToUpdate}
          >
            Continuar para alteração
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Info
