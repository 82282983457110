import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%',
  },
}))

const Buttons = ({ children, extraClasses = {} }) => (
  <div className={clsx(useStyles().buttons, extraClasses)}>{children}</div>
)

export default Buttons
