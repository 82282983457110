import { publicCallApi } from '../api'

const getQuestions = async () => {
  try {
    const response = await publicCallApi.getQuestions()

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const questions = responseBody.data.map(
        ({ id: questionId, attributes: question }) => ({
          ...question,
          id: parseInt(questionId),
          options: (question.options.data || []).map(
            ({ id: optionId, attributes: option }) => ({
              ...option,
              id: parseInt(optionId),
              label: option.title,
            })
          ),
        })
      )

      return {
        success: true,
        payload: { questions },
      }
    }
  } catch {}

  return { success: false, payload: { questions: [] }, error: 'UnhandledError' }
}

const addressService = {
  getQuestions,
}

export default addressService
