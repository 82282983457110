import FormControl from '@material-ui/core/FormControl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    margin: '8px 0',
  },
}))

const FormControlComponent = ({ ...rest }) => (
  <FormControl className={useStyles().formControl} {...rest} />
)

export default FormControlComponent
