import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

import { Editing, LoadingButton, StepHint } from '../../../../../components'
import { Main } from '../../../../../components/Layout'
import { Buttons } from '../../../../../components/Steps'

import Form from './elements/Form'
import Search from './elements/Search'

const Address = ({ data, errors, persist, prev, validating }) => {
  const [address, setAddress] = useState({ ...data })
  const [loading, setLoading] = useState(false)

  const update = attr => {
    setAddress({ ...address, ...attr })
  }

  const chooseAddressFromSearch = ({
    name,
    neighborhood,
    id,
    zipcode,
    search,
    results,
  }) => {
    setAddress({
      ...address,
      weak: false,
      streetId: id,
      street: name,
      zipcode,
      neighborhood,
      results,
      search,
    })
  }

  const shouldDisplayForm = () =>
    !!address.streetId || !!address.cityZipcode || address.weak

  return (
    <>
      <StepHint>
        Os campos devem ser preenchidos com o endereço de residência do aluno
        candidato
      </StepHint>

      <Main>
        <Editing />

        <Search
          address={address}
          chooseAddressFromSearch={chooseAddressFromSearch}
          errors={errors}
          loading={loading}
          validating={validating}
          setLoading={setLoading}
          update={update}
        />

        {shouldDisplayForm() && (
          <Form
            address={address}
            errors={errors}
            loading={loading}
            validating={validating}
            update={update}
          />
        )}
      </Main>

      <Buttons>
        <Button
          color="primary"
          disabled={validating}
          onClick={prev}
          variant="contained"
        >
          Voltar
        </Button>

        <LoadingButton
          disabled={!shouldDisplayForm() || validating}
          loading={validating}
          onClick={persist({ address })}
        >
          Próxima
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Address
