import Grid from '@material-ui/core/Grid'
import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { Subtitle } from '../../../../../../components'
import { appService } from '../../../../../../services'

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '16px',
  },
  subtitle: {
    marginBottom: '0',
    fontWeight: 600,
  },
}))

const Agreed = ({ agreed, submitting, setAgreed }) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} className={classes.marginTop}>
        <Subtitle className={classes.subtitle}>Confirmação</Subtitle>
      </Grid>

      <Grid item xs={12}>
        <div
          dangerouslySetInnerHTML={{
            __html: appService.getLocalPublicCall().info || '--',
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreed}
              disabled={submitting}
              onChange={e => setAgreed(e.target.checked)}
              name="agreed"
              color="primary"
            />
          }
          label="Declaro que todas as informações são verídicas e que qualquer informação ou documento falso, tornará nulo o cadastro efetuado"
        />
      </Grid>
    </>
  )
}

export default Agreed
