import Grid from '@material-ui/core/Grid'
import React from 'react'

import Divider from '@material-ui/core/Divider'

import { Label, Subtitle } from '../../../../../../components'

const General = ({ classes, suggested }) => (
  <>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Subtitle>Dados de ensino</Subtitle>
      </Grid>
    </Grid>

    {suggested() && (
      <>
        <Grid className={classes.suggestion} item xs={12}>
          <Label>
            Com base na idade do candidato, sugerimos o ano de escolaridade{' '}
            <strong>"{suggested()['label']}"</strong>
          </Label>
        </Grid>

        <Divider className={classes.suggestionDivider} />
      </>
    )}
  </>
)

export default General
