import ListItemIcon from '@material-ui/core/ListItemIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: '38px',
  },
}))

const DrawerIcon = ({ children, ...rest }) => (
  <ListItemIcon className={useStyles().icon} {...rest}>
    {children}
  </ListItemIcon>
)

export default DrawerIcon
