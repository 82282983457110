import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(() => ({
  typo: {
    color: '#797543',
  },
  alert: {
    backgroundColor: '#fff3cd',
    padding: '1rem',
    marginBottom: '16px',
  },
}))

const Editing = ({ children, ...rest }) => {
  const classes = useStyles()

  const data = () => {
    if (window.initialData) {
      try {
        return JSON.parse(window.initialData)
      } catch (e) {
        console.error(e)
      }
    }

    return {}
  }

  if (data().uuid) {
    return (
      <Paper elevation={1} className={classes.alert}>
        <Typography className={classes.typo} variant="subtitle2" {...rest}>
          <strong>Atenção!</strong>
          <br />
          Você está no modo de atualização da inscrição{' '}
          <strong>"{data().uuid}"</strong> realizada em{' '}
          <strong>{data().createdAt}</strong>.
          <br />
          Revise os dados com <strong>muita atenção</strong> e altere somente o
          necessário.
        </Typography>
      </Paper>
    )
  }

  return <></>
}

export default Editing
