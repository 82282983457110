import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import WarningOutlined from '@material-ui/icons/WarningOutlined'

import { colors, constants } from '../utils'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    minHeight: '240px',
  },
  icon: {
    fontSize: '100px',
    color: colors.theme.palette.grey['600'],
  },
  text: {
    margin: '16px 0',
    maxWidth: '320px',
    textAlign: 'center',
  },
}))

const NotFound = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <WarningOutlined className={classes.icon} />
      <Typography className={classes.text} variant="body1">
        {constants.strings.placeholders.notFound.text}
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => history.push(constants.routes.home.path)}
      >
        {constants.strings.placeholders.notFound.button}
      </Button>
    </div>
  )
}

export default NotFound
