import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  label: { fontWeight: 'bold' },
  extra: {
    marginTop: '4px',
  },
}))

const DescriptionItem = ({ label, value, extra, ...rest }) => {
  const classes = useStyles()

  return (
    <Grid item md={3} sm={6} xs={12} {...rest}>
      <Typography className={classes.label} variant="subtitle1">
        {label}
      </Typography>

      <Typography variant="body1">{value}</Typography>

      {extra !== undefined && (
        <Typography className={classes.extra} variant="body2">
          {extra}
        </Typography>
      )}
    </Grid>
  )
}

export default DescriptionItem
