import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(() => ({
  typo: {
    color: '#8f2731',
  },
  error: {
    backgroundColor: '#f8d7da',
    padding: '1rem',
  },
}))

const Error = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <Paper elevation={1} className={classes.error}>
      <Typography className={classes.typo} variant="subtitle2" {...rest}>
        {children}
      </Typography>
    </Paper>
  )
}

export default Error
