import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'

import {
  dates,
  enums,
  errors as errorUtils,
  strings,
} from '../../../../../../utils'

import { FormControl } from '../../../../../../components/Forms'
import { Subtitle } from '../../../../../../components'
import {
  CpfInput,
  PhoneOrMobileInput,
} from '../../../../../../components/Forms/Inputs'

const { responsibleKinds } = enums

const { keepOnlyUpcaseLettersAndBlankSpace, limit, upcase } = strings

const handleText = text =>
  keepOnlyUpcaseLettersAndBlankSpace(upcase(limit(text, 100)))

const Form = ({ errors, student, validating, responsible, update }) => {
  const hasError = errorUtils.hasError(errors)
  const errorMessage = errorUtils.errorMessage(errors)

  const handledName = () => {
    switch (responsible.kind) {
      case 'parent_one':
        return student.parentOne
      case 'parent_two':
        return student.parentTwo
      case 'self':
        return student.name
      default:
        return responsible.name
    }
  }

  const handledCpf = () =>
    responsible.kind === 'self' ? student.cpf : responsible.cpf

  const shouldDisableNameInput = () =>
    ['parent_one', 'parent_two', 'self'].includes(responsible.kind)

  const shouldDisableCpfInput = () => responsible.kind === 'self' || validating

  const filteredKinds = () => {
    let unpermitted = []

    if (student.unknownParentOne) {
      unpermitted.push('parent_one')
    }

    if (student.unknownParentTwo) {
      unpermitted.push('parent_two')
    }

    if (!dates.ofLegalAge(student.birthdate)) {
      unpermitted.push('self')
    }

    return responsibleKinds.filter(i => !unpermitted.includes(i.value))
  }

  const nameInputProps = () =>
    shouldDisableNameInput() ? { shrink: true } : {}

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle>Responsável pelo candidato</Subtitle>
        </Grid>

        <Grid item sm={3} xs={12}>
          <FormControl variant="outlined" error={hasError('rKind')}>
            <InputLabel htmlFor="kind">Tipo</InputLabel>
            <Select
              autoFocus
              disabled={validating}
              error={hasError('rKind')}
              id="kind"
              label="Tipo"
              onChange={e => update({ kind: e.target.value })}
              value={responsible.kind || ''}
            >
              {filteredKinds().map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>

            {hasError('rKind') && (
              <FormHelperText>{errorMessage('rKind')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item sm={9} xs={12}>
          <FormControl>
            <TextField
              disabled={shouldDisableNameInput() || validating}
              error={hasError('rName')}
              helperText={errorMessage('rName')}
              InputLabelProps={nameInputProps()}
              label="Nome completo do responsável"
              name="name"
              onChange={e => update({ name: handleText(e.target.value) })}
              value={handledName() || ''}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined" error={hasError('rCpf')}>
            <InputLabel htmlFor="cpf">CPF do responsável</InputLabel>
            <OutlinedInput
              disabled={shouldDisableCpfInput()}
              error={hasError('rCpf')}
              id="cpf"
              inputComponent={CpfInput}
              label="CPF do responsável"
              name="cpf"
              onChange={e => update({ cpf: e.target.value })}
              value={handledCpf() || ''}
              variant="outlined"
            />

            {hasError('rCpf') && (
              <FormHelperText>{errorMessage('rCpf')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined" error={hasError('rMobile')}>
            <InputLabel htmlFor="mobile">Celular</InputLabel>
            <OutlinedInput
              disabled={validating}
              error={hasError('rMobile')}
              id="mobile"
              inputComponent={PhoneOrMobileInput}
              label="Celular"
              name="mobile"
              onChange={e => update({ mobile: e.target.value })}
              value={responsible.mobile || ''}
              variant="outlined"
            />

            {hasError('rMobile') && (
              <FormHelperText>{errorMessage('rMobile')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="altPhone">
              Telefone alternativo (opcional)
            </InputLabel>
            <OutlinedInput
              disabled={validating}
              id="altPhone"
              inputComponent={PhoneOrMobileInput}
              label="Telefone alternativo (opcional)"
              name="altPhone"
              onChange={e => update({ altPhone: e.target.value })}
              value={responsible.altPhone || ''}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl>
            <TextField
              disabled={validating}
              label="E-mail (opcional)"
              name="email"
              onChange={e => update({ email: e.target.value })}
              type="email"
              value={responsible.email || ''}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={responsible.newsletter || false}
                color="primary"
                disabled={validating}
                name="newsletter"
                onChange={e => update({ newsletter: e.target.checked })}
              />
            }
            label="Aceito recebimento de comunicações (exclusivo para chamada escolar)"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Form
