import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const getAllCustomerCities = async () =>
  fetch(url(constants.api.customer.cities), {
    method: 'GET',
    headers: headers(),
  })

const customerApi = {
  getAllCustomerCities,
}

export default customerApi
