import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { DescriptionItem, Subtitle } from '../../../../../../components'
import { enums, options } from '../../../../../../utils'

import AnswersBuilder from './AnswersBuilder'

const { findByValue } = enums
const { optComplete, questionsFor } = options

const useStyles = makeStyles(() => ({
  subtitle: {
    fontWeight: 600,
  },
  empty: {
    margin: '0 6px 16px',
    width: '100%',
  },
  divider: {
    margin: '16px auto',
    width: '50%',
  },
  separator: {
    margin: '28px auto',
    width: '90%',
  },
}))

const OptionBuilder = ({ label, option, questions, divider = false }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matchBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  const { key, data } = option

  const shouldDisplayAnswers = () =>
    Array.isArray(data.answers) && data.answers.length > 0

  return (
    <Grid item md={4} xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle className={classes.subtitle}>{label}</Subtitle>
        </Grid>

        {optComplete(questions)({ [key]: data }) ? (
          <>
            <DescriptionItem
              label="Ano de escolaridade"
              value={data.degreeName}
              md={12}
              sm={12}
            />

            <DescriptionItem
              label="Cidade"
              value={data.cityName}
              md={12}
              sm={12}
            />

            <DescriptionItem
              label="Escola de preferência"
              value={data.schoolName}
              md={12}
              sm={12}
            />

            <DescriptionItem
              label="Turno de preferência"
              value={findByValue('shifts', data.shift)}
              md={12}
              sm={12}
            />

            <DescriptionItem
              label="Ano/Série/Etapa"
              value={data.curriculumName}
              md={12}
              sm={12}
            />

            {shouldDisplayAnswers() && (
              <>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>

                <AnswersBuilder
                  questions={questionsFor(questions)({ [key]: data })}
                  answers={data.answers}
                />
              </>
            )}
          </>
        ) : (
          <p className={classes.empty}>Não preenchida</p>
        )}

        {matchBreakpoint && divider && (
          <Divider className={classes.separator} />
        )}
      </Grid>
    </Grid>
  )
}

export default OptionBuilder
