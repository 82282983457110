import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { constants } from '../../../../utils'

import {
  DescriptionItem,
  StepHint,
  LoadingButton,
} from '../../../../components'
import { Main } from '../../../../components/Layout'
import { Buttons } from '../../../../components/Steps'
import { subscriptionService } from '../../../../services'

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '60px',
    margin: '0 auto',
  },
  title: {
    margin: '24px 0 36px',
    textAlign: 'center',
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  margin: {
    marginTop: '12px',
  },
}))

const Finished = ({ data, student }) => {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const tryToGetPdf = async () => {
    setLoading(true)

    await subscriptionService.getPdf(data.uuid)

    setLoading(false)
  }

  const pageTitle = () => (data.updated ? 'atualizada' : 'realizada')

  return (
    <>
      <StepHint>
        Aqui está o comprovante de inscrição, lembre-se de imprimir ou salvar o
        arquivo PDF. Este documento será solicitado no ato da matrícula.
      </StepHint>

      <Main>
        <CheckCircleOutline className={classes.icon} />

        <Typography className={classes.title} variant="h5">
          Inscrição {pageTitle()} com sucesso!
        </Typography>

        <Grid container spacing={1}>
          <DescriptionItem
            label="Protocolo de inscrição"
            value={data.uuid}
            md={12}
            sm={12}
          />

          <DescriptionItem
            label="Realizada em"
            value={data.createdAt}
            md={4}
            sm={12}
          />

          <DescriptionItem label="IP Público" value={data.ip} md={3} sm={12} />

          <DescriptionItem label="Status" value={data.status} md={3} sm={12} />

          <DescriptionItem
            label="Código de verificação"
            value={data.verificationCode}
            md={2}
            sm={12}
          />

          <DescriptionItem
            label="Nome do candidato"
            value={student.name}
            md={7}
            sm={12}
          />

          <DescriptionItem
            label="CPF do candidato"
            value={student.cpf}
            md={5}
            sm={12}
          />

          {data.updated && (
            <DescriptionItem
              label="Atualizada em"
              value={data.updatedAt}
              md={12}
            />
          )}
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        <LoadingButton
          disabled={loading}
          loading={loading}
          onClick={tryToGetPdf}
        >
          Imprimir Comprovante da Inscrição
        </LoadingButton>

        <Button
          className={classes.margin}
          variant="contained"
          color="default"
          onClick={() => history.push(constants.routes.home.path)}
        >
          Voltar para início
        </Button>
      </Buttons>
    </>
  )
}

export default Finished
