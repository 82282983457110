import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { DescriptionItem, Subtitle } from '../../../../../../components'

import { enums } from '../../../../../../utils'

const { findByValue } = enums

const useStyles = makeStyles(() => ({
  divider: {
    margin: '16px 0',
  },
}))

const handledName = (responsible, student) => {
  switch (responsible.kind) {
    case 'parent_one':
      return student.parentOne
    case 'parent_two':
      return student.parentTwo
    case 'self':
      return student.name
    default:
      return responsible.name
  }
}

const handledCpf = (responsible, student) =>
  responsible.kind === 'self' ? student.cpf : responsible.cpf

const Responsible = ({ responsible, student }) => (
  <>
    <Grid item xs={12}>
      <Subtitle>Dados do responsável</Subtitle>
    </Grid>

    <DescriptionItem
      label="Nome"
      value={handledName(responsible, student)}
      md={6}
    />
    <DescriptionItem label="CPF" value={handledCpf(responsible, student)} />

    <DescriptionItem
      label="Tipo"
      value={findByValue('responsibleKinds', responsible.kind)}
    />

    <DescriptionItem label="Celular" value={responsible.mobile} />
    <DescriptionItem
      label="Tel. alternativo"
      value={responsible.altPhone || '--'}
    />
    <DescriptionItem label="E-mail" value={responsible.email || '--'} />

    <DescriptionItem
      label="Aceita comunicações?"
      value={findByValue('trueAndFalse', responsible.newsletter)}
    />

    <Grid item xs={12}>
      <Divider className={useStyles().divider} />
    </Grid>
  </>
)

export default Responsible
