import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { DescriptionItem } from '../../../../../../components'

const useStyles = makeStyles(() => ({
  question: {
    marginBottom: '8px',
  },
  notFound: {
    margin: '0 6px',
    width: '100%',
    color: '#9c5c6a',
  },
}))

const AnswersBuilder = ({ questions, answers }) => {
  const classes = useStyles()

  const findAnswerById = (options, id) =>
    (options.find(i => i.id === id) || {}).label

  return (questions || []).map(question => {
    const answer = (answers || []).find(i => i.questionId === question.id)

    if (answer) {
      const kind = question.options.find(i => i.id === answer.answerId).kind

      return (
        <DescriptionItem
          key={question.id}
          className={classes.question}
          label={question.title}
          value={findAnswerById(question.options, answer.answerId)}
          extra={kind !== 'basic' ? `Resp.: ${answer.extra}` : undefined}
          md={12}
          sm={12}
        />
      )
    } else {
      return (
        <p key={question.id} className={classes.notFound}>
          ERRO: Pergunta ID {question.id} não encontrada, consulte a Secretaria
          de Educação para ajuda
        </p>
      )
    }
  })
}

export default AnswersBuilder
