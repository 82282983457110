import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'

import { Alert, DescriptionItem, Error, Subtitle } from '../../../../components'

import { Main } from '../../../../components/Layout'
import { Buttons } from '../../../../components/Steps'
import { dates } from '../../../../utils'
import { renewEnrollmentService } from '../../../../services'

const { format } = dates

const useStyles = makeStyles(() => ({
  alert: {
    marginBottom: '15px',
  },
  button: {
    marginTop: '16px',
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: '24px 0',
  },
  error: { marginBottom: '16px' },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
}))

const Found = ({ data, setEnrollment, setRenewing }) => {
  const classes = useStyles()

  const [agreed, setAgreed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (loading) return

    setOpen(false)
    setAgreed(false)
    setError(null)
  }

  const tryToCancel = async () => {
    setLoading(true)

    const { success } = await renewEnrollmentService.tryToCancel(data.id)

    if (success) {
      setEnrollment({
        ...data,
        requested: false,
        requestedAt: null,
        requestedCurriculum: null,
        requestedDegree: null,
        requestedFromCpe: false,
      })

      setOpen(false)
    } else {
      setError(
        'Não foi possível cancelar a solicitação, tente novamente mais tarde ou consulte a Secretaria de Educação para ajuda.'
      )
    }

    setLoading(false)
  }

  return (
    <>
      <Main className={classes.main}>
        <Grid container spacing={1}>
          {!!data.renewMessage && (
            <Grid item className={classes.alert} xs={12}>
              <Alert>{data.renewMessage}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Subtitle>Dados do aluno</Subtitle>
          </Grid>

          <DescriptionItem label="Aluno" value={data.name} md={12} />

          <DescriptionItem
            label="Data de nascimento"
            value={format(data.birthdate)}
            md={4}
          />

          <DescriptionItem label="Filiação 1" value={data.parentOne} md={4} />
          <DescriptionItem label="Filiação 2" value={data.parentTwo} md={4} />

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Subtitle>Dados da matrícula ativa</Subtitle>
          </Grid>

          <DescriptionItem
            label="Ano de escolaridade"
            value={data.degree}
            md={6}
          />
          <DescriptionItem label="Turma" value={data.classroom} md={6} />

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Subtitle>Dados da renovação de matrícula</Subtitle>
          </Grid>

          <DescriptionItem
            label="Situação"
            value={
              data.requested
                ? `Renovação solicitada em ${format(data.requestedAt)}`
                : 'Renovação não solicitada'
            }
            md={6}
          />

          {data.requested && (
            <>
              <DescriptionItem
                label="Solicitada via"
                value={
                  data.requestedFromCpe
                    ? 'Portal da Chamada Escolar'
                    : 'Sistema acadêmico'
                }
                md={6}
              />

              {!!data.requestedDegree && (
                <DescriptionItem
                  label="Ano de escolaridade"
                  value={data.requestedDegree}
                  md={6}
                />
              )}

              {!!data.requestedCurriculum && (
                <DescriptionItem
                  label="Organização curricular"
                  value={data.requestedCurriculum}
                  md={6}
                />
              )}
            </>
          )}
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        {data.requested ? (
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={handleClickOpen}
          >
            Cancelar solicitação de renovação
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => setRenewing(true)}
          >
            Solicitar renovação
          </Button>
        )}
      </Buttons>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Deseja seguir com o cancelamento da solicitação?
        </DialogTitle>

        <DialogContent dividers>
          {!!error && (
            <Grid item xs={12} className={classes.error}>
              <Error>
                <span>{error}</span>
              </Error>
            </Grid>
          )}

          <DialogContentText>
            Ao prosseguir com o cancelamento da solicitação de renovação de
            matrícula, você terá que solicita-la novamente através deste portal
            ou diretamente à Secretaria de Educação.
          </DialogContentText>

          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                disabled={loading}
                onChange={e => setAgreed(e.target.checked)}
                name="agreed"
                color="primary"
              />
            }
            label="Li e estou de acordo"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Desistir
          </Button>

          <Button
            color="primary"
            disabled={loading || !agreed}
            onClick={tryToCancel}
          >
            Continuar com cancelamento
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Found
