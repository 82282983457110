import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { FormControl } from '../../../../../../components/Forms'
import { Alert, Subtitle } from '../../../../../../components'
import {
  BirthdateInput,
  CpfInput,
} from '../../../../../../components/Forms/Inputs'

import { enums, errors as errorUtils } from '../../../../../../utils'

const { trueAndFalse, genders } = enums

const useStyles = makeStyles(() => ({
  alert: {
    margin: '0 0 8px',
  },
}))

const Basic = ({ errors, handleText, validating, student, update }) => {
  const classes = useStyles()

  const hasError = errorUtils.hasError(errors)
  const errorMessage = errorUtils.errorMessage(errors)

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle>Informações básicas</Subtitle>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <TextField
              autoFocus
              disabled={validating}
              error={hasError('name')}
              helperText={errorMessage('name')}
              label="Nome completo do candidato"
              name="name"
              value={student.name || ''}
              variant="outlined"
              onChange={e => update({ name: handleText(e.target.value) })}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={student.hasSocialName || false}
                disabled={validating}
                onChange={e => update({ hasSocialName: e.target.checked })}
                name="hasSocialName"
                color="primary"
              />
            }
            label="Desejo informar nome social do candidato"
          />
        </Grid>

        {student.hasSocialName && (
          <>
            <Grid item xs={12} className={classes.alert}>
              <Alert>
                Designação pela qual a pessoa travesti, transexual ou não se
                identifica e é socialmente reconhecida. (Res. CEE N.º 2.735 de
                2011)
              </Alert>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <TextField
                  disabled={validating}
                  error={hasError('socialName')}
                  helperText={errorMessage('socialName')}
                  label="Nome social do candidato"
                  name="socialName"
                  value={student.socialName || ''}
                  variant="outlined"
                  onChange={e =>
                    update({ socialName: handleText(e.target.value) })
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined" error={hasError('cpf')}>
            <InputLabel htmlFor="cpf">CPF do candidato</InputLabel>
            <OutlinedInput
              disabled={validating}
              error={hasError('cpf')}
              label="CPF do candidato"
              variant="outlined"
              id="cpf"
              name="cpf"
              value={student.cpf || ''}
              onChange={e => update({ cpf: e.target.value })}
              inputComponent={CpfInput}
            />

            {hasError('cpf') && (
              <FormHelperText>{errorMessage('cpf')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined" error={hasError('birthdate')}>
            <InputLabel htmlFor="birthdate">Data de nascimento</InputLabel>
            <OutlinedInput
              disabled={validating}
              error={hasError('birthdate')}
              label="Data de nascimento"
              variant="outlined"
              id="birthdate"
              name="birthdate"
              value={student.birthdate || ''}
              onChange={e => update({ birthdate: e.target.value })}
              inputComponent={BirthdateInput}
            />

            {hasError('birthdate') && (
              <FormHelperText>{errorMessage('birthdate')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined" error={hasError('gender')}>
            <InputLabel htmlFor="gender">Sexo</InputLabel>
            <Select
              disabled={validating}
              error={hasError('gender')}
              label="Sexo"
              id="gender"
              value={student.gender || ''}
              onChange={e => update({ gender: e.target.value })}
            >
              {genders.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>

            {hasError('gender') && (
              <FormHelperText>{errorMessage('gender')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <FormControl variant="outlined" error={hasError('twin')}>
            <InputLabel htmlFor="twin">Possui irmão gêmeo?</InputLabel>
            <Select
              disabled={validating}
              error={hasError('twin')}
              label="Possui irmão gêmeo?"
              id="twin"
              value={typeof student.twin === 'boolean' ? student.twin : ''}
              onChange={e => update({ twin: e.target.value })}
            >
              {trueAndFalse.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>

            {hasError('twin') && (
              <FormHelperText>{errorMessage('twin')}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl>
            <TextField
              error={hasError('parentOne')}
              helperText={errorMessage('parentOne')}
              disabled={student.unknownParentOne || validating}
              label="Filiação 1 (preferencialmente mãe)"
              variant="outlined"
              name="parentOne"
              value={student.parentOne || ''}
              onChange={e => update({ parentOne: handleText(e.target.value) })}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                disabled={student.unknownParentTwo || validating}
                checked={student.unknownParentOne || false}
                onChange={e =>
                  update({
                    unknownParentOne: e.target.checked,
                    parentOne: e.target.checked ? undefined : student.parentOne,
                  })
                }
                name="unknownParentOne"
                color="primary"
              />
            }
            label="Filiação 1 não informada"
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl>
            <TextField
              error={hasError('parentTwo')}
              helperText={errorMessage('parentTwo')}
              disabled={student.unknownParentTwo || validating}
              label="Filiação 2 (preferencialmente pai)"
              variant="outlined"
              name="parentTwo"
              value={student.parentTwo || ''}
              onChange={e => update({ parentTwo: handleText(e.target.value) })}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                disabled={student.unknownParentOne || validating}
                checked={student.unknownParentTwo || false}
                onChange={e =>
                  update({
                    unknownParentTwo: e.target.checked,
                    parentTwo: e.target.checked ? undefined : student.parentTwo,
                  })
                }
                name="unknownParentTwo"
                color="primary"
              />
            }
            label="Filiação 2 não informada"
          />
        </Grid>
      </Grid>
    </>
  )
}
export default Basic
