import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Select from '@material-ui/core/Select'

import { FormControl } from '../../../../../../components/Forms'
import { Subtitle } from '../../../../../../components'

import { enums, errors as errorUtils } from '../../../../../../utils'

const { studyPlaces } = enums

const Study = ({ classes, errors, validating, student, update }) => {
  const hasError = errorUtils.hasError(errors)
  const errorMessage = errorUtils.errorMessage(errors)

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.marginTop}>
        <Subtitle>Dados escolares</Subtitle>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="outlined" error={hasError('studyPlace')}>
          <InputLabel htmlFor="studyPlace">
            Local atual de estudo do candidato
          </InputLabel>
          <Select
            disabled={validating}
            error={hasError('studyPlace')}
            label="Local atual de estudo do candidato"
            id="studyPlace"
            value={student.studyPlace || ''}
            onChange={e => update({ studyPlace: e.target.value })}
          >
            {studyPlaces.map(i => (
              <MenuItem key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </Select>

          {hasError('studyPlace') && (
            <FormHelperText>{errorMessage('studyPlace')}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Study
