import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import makeStyles from '@material-ui/core/styles/makeStyles'
import moment from 'moment'

import { Editing, LoadingButton, StepHint } from '../../../../../components'
import { dates } from '../../../../../utils'
import { Main } from '../../../../../components/Layout'
import { Buttons } from '../../../../../components/Steps'
import { intentService } from '../../../../../services'

import Agreed from './elements/Agreed'
import General from './elements/General'
import Options from './elements/Options'

const { parsePtBr } = dates

const useStyles = makeStyles(() => ({
  suggestion: {
    marginTop: '4px',
  },
  error: {
    marginTop: '16px',
  },
  suggestionDivider: {
    margin: '20px 0 10px',
  },
  divider: {
    marginTop: '20px',
  },
  ul: {
    margin: '8px 0 0',
    paddingLeft: '24px',
  },
}))

const Intent = ({ data, errors, persist, prev, validating }) => {
  const classes = useStyles()

  const [degrees, setDegrees] = useState([])
  const [intent, setIntent] = useState({ ...data.intent })
  const [agreed, setAgreed] = useState(false)
  const [loading, setLoading] = useState(false)

  const tryToDetectDegreeRef = () => {
    try {
      return intent.optOne.degreeRef
    } catch {
      return undefined
    }
  }

  const [degreeRef, setDegreeRef] = useState(tryToDetectDegreeRef())

  const suggested = () =>
    degrees
      .sort((a, b) => (moment(a.from).isBefore(b.from) ? 1 : -1))
      .find(
        i =>
          parsePtBr(data.student.birthdate).isSameOrAfter(moment(i.from)) &&
          parsePtBr(data.student.birthdate).isSameOrBefore(moment(i.to))
      )

  const tryToGetAvailableDegrees = async () => {
    setLoading(true)

    const {
      success,
      payload: { degrees: _degrees },
    } = await intentService.getAllPublicCallDegrees()

    if (success) {
      setDegrees(_degrees)
    }

    setLoading(false)
  }

  const update = attr => {
    setIntent({ ...intent, ...attr })
  }

  const shouldDisableNextButton = () => {
    try {
      return !intent.optOne.completed || !agreed || validating
    } catch {
      return true
    }
  }

  useEffect(() => {
    tryToGetAvailableDegrees()
  }, [])

  useEffect(() => {
    const found = suggested()

    if (found && !degreeRef) {
      setDegreeRef(found.value)

      setIntent({
        ...intent,
        optOne: {
          degreeRef: found.value,
          degreeName: found.label,
        },
      })
    }
  }, [degrees])

  return (
    <>
      <StepHint>
        Agora é hora de escolher a(s) opção(ões) de escola e oferta
      </StepHint>

      <Main>
        <Editing />

        <General
          classes={classes}
          degrees={degrees}
          errors={errors}
          intent={intent}
          loading={loading}
          setLoading={setLoading}
          validating={validating}
          student={data.student}
          suggested={suggested}
          update={update}
        />

        <Options
          degrees={degrees}
          errors={errors}
          intent={intent}
          loading={loading}
          validating={validating}
          setLoading={setLoading}
          update={update}
        />

        <Divider className={classes.divider} />

        <Agreed agreed={agreed} validating={validating} setAgreed={setAgreed} />
      </Main>

      <Buttons>
        <Button variant="contained" color="primary" onClick={prev}>
          Voltar
        </Button>

        <LoadingButton
          disabled={shouldDisableNextButton()}
          loading={validating}
          onClick={persist({ intent })}
        >
          Próxima
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Intent
