import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import React, { Fragment } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  list: {
    padding: '0',
    marginTop: '8px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
}))

const humanize = ({ name, neighborhood, zipcode }) =>
  `${name} - ${neighborhood} - CEP ${zipcode}`

const Results = ({
  addresses,
  choose,
  disabled,
  loading,
  notFound,
  search,
  searched,
  streetId,
}) => {
  const classes = useStyles()

  const loadingStyle = () => (loading ? { opacity: '.75' } : {})

  if (searched) {
    return (
      <List
        component="ul"
        className={classes.list}
        style={loadingStyle()}
        subheader={
          <ListSubheader component="div">
            Endereços encontrados, selecione...
          </ListSubheader>
        }
      >
        <>
          <Divider component="li" />

          {notFound ? (
            <ListItem>
              <ListItemText primary="Nenhum resultado encontrado para sua busca" />
            </ListItem>
          ) : (
            addresses.map((address, idx) => (
              <Fragment key={address.id}>
                <ListItem
                  component="li"
                  button
                  disabled={disabled}
                  selected={streetId === address.id}
                >
                  <ListItemText
                    primary={humanize(address)}
                    onClick={() =>
                      choose({ ...address, search, results: addresses })
                    }
                  />
                </ListItem>

                {idx < addresses.length - 1 && <Divider component="li" />}
              </Fragment>
            ))
          )}
        </>
      </List>
    )
  }

  return <></>
}

export default Results
