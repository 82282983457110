import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { PageHeader } from '../../../components/Layout'
import { constants } from '../../../utils'
import { appService, subscriptionService } from '../../../services'

import { Classification, Form, Found } from './elements'

const Search = () => {
  const history = useHistory()

  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(false)
  const [subscription, setSubscription] = useState(null)

  const shouldDisplayResult = () =>
    subscription && subscription.classification.id

  useEffect(() => {
    if (appService.shouldRedirectToHome()) {
      history.push(constants.routes.home.path)
    }
  }, [])

  const tryToSearch = (cpf, cpfResponsible) => async () => {
    setLoading(true)

    const { success, payload } = await subscriptionService.get(
      cpf,
      cpfResponsible
    )

    if (success) {
      setSubscription(payload)
      setNotFound(false)
    } else {
      setNotFound(true)
    }

    setLoading(false)
  }

  const renderTitle = () =>
    shouldDisplayResult() ? 'Resultado da inscrição' : 'Consultar inscrição'

  const renderDetailsOrResult = () => {
    if (shouldDisplayResult()) {
      return <Classification data={subscription} />
    }

    return <Found data={subscription} />
  }

  return (
    <>
      <PageHeader
        buttonText="Voltar"
        title={renderTitle()}
        onClick={() => history.push(constants.routes.home.path)}
      />

      {subscription ? (
        renderDetailsOrResult()
      ) : (
        <Form loading={loading} notFound={notFound} tryToSearch={tryToSearch} />
      )}
    </>
  )
}

export default Search
