import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  circularProgress: {
    marginRight: '10px',
  },
}))

const LoadingButton = ({ children, loading, ...rest }) => {
  const classes = useStyles()

  return (
    <Button variant="contained" color="primary" {...rest}>
      {loading && (
        <CircularProgress className={classes.circularProgress} size={20} />
      )}
      {children}
    </Button>
  )
}

export default LoadingButton
